import { useEffect, useState } from "react";

interface Props {
  setIframe: (value: boolean) => void;
}
export function RouletteButton(props: Props) {
  const { setIframe } = props;

  useEffect(() => {}, []);

  return (
    <div className="absolute bottom-1 left-1 flex flex-col gap-1" style={{zIndex: 2}}>
      <button
        className="w-max h-10 text-white text-sm rounded-full flex items-center font-bold justify-center p-1 relative"
        onClick={() => {
          setIframe(true);
        }}
      >
        <img src="/wheel.png" alt="wheel" className="w-10 h-10" />
        <div
          className="absolute text-xs font-bold bg-red px-1 rounded-xl opacity-80"
          style={{ right: -4, top: -1, color: "#fff" }}
        >
          New!
        </div>
      </button>
    </div>
  );
}
