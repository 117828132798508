import { User } from "../logic/server";
import websocket from "../logic/websocket";

interface Props {
  user?: User;
}
export function TopRightBrowser(props: Props) {
  const { user } = props;

  return user?.auth?.token ? (
    <div className="absolute right-0 top-1/2 transform -translate-y-1/2 flex flex-row w-max h-full">
      {websocket.telegramInitData ? (
        <a
          href={
            (window?.location.origin === "http://localhost:3000"
              ? "http://localhost:3000"
              : "https://odyssey.pfplabs.xyz") +
            "/?token=" +
            user.auth.token +
            "&locale=" +
            user.auth.locale
          }
          target="_blank"
          rel="noreferrer"
          className="flex items-center flex-row text-sm text-white font-medium justify-center h-full"
        >
          <p style={{ marginTop: -2 }}>open in web</p>
          <img
            className="w-5 h-5"
            src="/open_in_new.svg"
            style={{ marginLeft: 3 }}
          />
        </a>
      ) : (
        <div
          className="flex items-center flex-row text-sm text-white font-medium justify-center h-full cursor-pointer"
          onClick={() => {
            localStorage.setItem("token", "");
            window.location.reload();
          }}
        >
          <p style={{ marginTop: -2 }}>log out</p>
        </div>
      )}
    </div>
  ) : null;
}
