import websocket from "../logic/websocket";
import { Panel } from "./Panel";

interface Props {
  isSelectLanguageOpen: boolean;
  setIsSelectLanguageOpen: (flag: boolean) => void;
  chatLanguage: string;
  setChatLanguage: (language: string) => void;
}

const languages = [
  "arabic",
  "english",
  "farsi",
  "korean",
  "russian",
  "uzbek",
  "vietnamese",
];

const names: any = {
  arabic: "العربية",
  english: "English",
  farsi: "فارسی",
  korean: "한국어",
  russian: "Русский",
  uzbek: "O'zbek",
  vietnamese: "Tiếng Việt",
};

export function SelectLanguage(props: Props) {
  const {
    isSelectLanguageOpen,
    setIsSelectLanguageOpen,
    chatLanguage,
    setChatLanguage,
  } = props;

  return (
    <Panel onClose={() => setIsSelectLanguageOpen(false)}>
      <p className="text-2xl text-grey-title">Chat Room</p>
      <div className="grid grid-cols-2 gap-2 mt-4">
        {languages.map((lang) => (
          <button
            key={lang}
            onClick={() => {
              setChatLanguage(lang);
              websocket.getMessages(lang);
              setIsSelectLanguageOpen(false);
            }}
            className={
              "text-md text-grey-title flex flex-row items-start justify-left w-full gap-2 px-5 py-1 rounded-3xl" +
              (chatLanguage === lang ? " bg-grey-black" : "")
            }
          >
            <img src={`/flags/${lang}.svg`} alt={lang} className="h-6 w-6" />
            {names[lang]}
          </button>
        ))}
      </div>
    </Panel>
  );
}
