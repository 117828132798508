import { MainScene } from "../MainScene";

export function createBackground(
  scene: MainScene,
  width: number,
  height: number
) {
  let scale = 1;

  scene.starsBig = scene.add
    .tileSprite(0, 0, width / scale, height / scale, "stars-big")
    .setOrigin(0, 0)
    .setDepth(-19)
    .setScale(scale);

  scene.starsSmall = scene.add
    .tileSprite(0, 0, width / scale, height / scale, "stars-small")
    .setOrigin(0, 0)
    .setDepth(-18)
    .setScale(scale);
}

export async function animateBackground(scene: MainScene) {
  let speed = 0;
  if (
    // this.spaceBack !== undefined &&
    scene.starsSmall !== undefined &&
    scene.starsBig !== undefined
  ) {
    scene.starsBig.setTilePosition(
      scene.starsBig.tilePositionX + 1.75 + speed,
      scene.starsBig.tilePositionY - 0.1 - speed
    );

    scene.starsSmall.setTilePosition(
      scene.starsSmall.tilePositionX + 3 + speed,
      scene.starsSmall.tilePositionY - 0.1 - speed
    );
  }
}
