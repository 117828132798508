import { useEffect, useRef, useState } from "react";
import { Game as PhaserGame } from "phaser";
import { TopLeftBusts } from "./TopLeftBusts";
import { RouletteButton } from "./RouletteButton";
import useWindowSize from "../hooks/useWindowSize";
import { GameData } from "../logic/websocket/types";
import gameConfig from "../game";
import websocket from "../logic/websocket";
import { setIsGameReadyMainScene } from "../game/scenes/MainScene";

export let stackFunctions: (() => void)[] = [];

interface Props {
  setIframe: (value: boolean) => void;
}

export default function Game(props: Props) {
  const { setIframe } = props;
  const parentEl = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);

  const size = useWindowSize();

  let [game, setGame] = useState<PhaserGame | undefined>(undefined);

  let [gameState, setGameState] = useState<GameData>();

  let [isGameReady, setIsGameReady] = useState<boolean>(false);

  useEffect(() => {
    if (!parentEl.current) return;

    const newGame = new PhaserGame({
      ...gameConfig,
      parent: parentEl.current,
      width: parentEl.current.offsetWidth,
      height: parentEl.current.offsetHeight,
    });

    websocket.setGameState = setGameState;
    setIsGameReadyMainScene.func = setIsGameReady;
    websocket.updateGameState();

    setGame(newGame);

    return () => {
      websocket.mainScene?.tweens?.destroy?.();
      websocket.mainScene?.children?.destroy?.();
      websocket.mainScene = undefined;
      newGame?.destroy(false);
      console.log("🐲 DESTROY 🐲");
    };
  }, []);

  useEffect(() => {
    console.log("New Game State: ", gameState);
    console.log("IS GAME READY", isGameReady);
    if (game) {
      if (!websocket.mainScene) {
        return;
      }

      if (gameState?.state === "IN_PROGRESS" && isGameReady) {
        websocket.mainScene.setupGame?.();
      } else if (gameState?.state === "ENDED" && isGameReady) {
        websocket.mainScene.setupGame?.();
      } else if (gameState?.state === "STARTING" && isGameReady) {
        websocket.mainScene.setupGame?.();
      }
    }
  }, [gameState, isGameReady]);

  useEffect(() => {
    if (
      !game ||
      !parentEl.current?.offsetWidth ||
      !parentEl.current.offsetHeight
    )
      return;

    console.log("LAUNCH AGAIN");

    console.log(parentEl.current?.offsetWidth, parentEl.current?.offsetHeight);

    game?.scale?.setGameSize(
      parentEl.current?.offsetWidth,
      parentEl.current?.offsetHeight
    );

    // NEEDED 2 TIMES OTHERWISE IT'S A BUG
    // PHASER BUG I DON'T KNOW HOW TO FIX
    console.log(
      "changed to",
      parentEl?.current?.offsetWidth,
      parentEl?.current?.offsetHeight
    );
    game?.scale?.setGameSize(
      parentEl?.current?.offsetWidth,
      parentEl?.current?.offsetHeight
    );

    game?.scene?.start("MainScene");
  }, [size]);

  return (
    <div ref={container} className="w-full h-56 shrink-0 max-w-lg rounded-2xl overflow-hidden relative">
      {/* // make background image  */}
      <TopLeftBusts />
      <RouletteButton setIframe={setIframe} />
      <canvas
        className="absolute top-0 left-0 w-full h-full bg-[url('/public/assets/background-2.svg')] bg-cover bg-bottom"
        id="graph"
        width={(container.current?.offsetWidth || 0) * 2}
        height={(container.current?.offsetHeight || 0) * 2}
        style={{
          width: container.current?.offsetWidth,
          height: container.current?.offsetHeight,
        }}
      ></canvas>
      <div
        ref={parentEl}
        className="absolute gameContainer w-full h-full m-0 p-0 shrink-0"
        id="game-phaser"
      >
        <div className="z-10 absolute h-52 block text-white flex text-5xl sm:text-3xl mt-8 font-black left-1/2 transform -translate-x-1/2">
          <div className="flex-col text-center">
            <div className="whitespace-nowrap text-2xl" id="multiplyer">
              loading...
            </div>
            <div
              id="user-bet"
              className="font-bold text-xl text-green"
              style={{ display: "none" }}
            >
              +$0.000
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
