import { TokenIcon } from "./Menu/TokenIcon";

interface Props {
  color: string;
  bgColor: string;
  text: string;
  height?: string;
  maxW?: string;
  isLocked?: boolean;
  onClick: () => void;
  link?: string;
  icon?: string;
}
export function Button(props: Props) {
  const { color, bgColor, text, height, maxW, onClick, isLocked, icon } = props;
  let h = height ? height : "h-12";
  let mw = maxW ? maxW : "none";
  const button = (
    <button
      onClick={() => {
        if (isLocked) return;
        onClick();
      }}
      className={`flex justify-center items-center w-full ${h} ${mw} text-base ${color} ${bgColor} text-base font-bold rounded-3xl drop-shadow-lg  shrink-0 ${
        isLocked ? "cursor-not-allowed opacity-70" : ""
      }`}
    >
      {icon && <TokenIcon src={icon} height="22px" />}
      <p className="inline-block">{text}</p>
    </button>
  );

  // if link  is provided, return a link with the button inside

  if (props.link) {
    return (
      <a href={props.link} rel="noreferrer" target="_blank" className={`w-full ${h} ${mw}`}>
        {button}
      </a>
    );
  }

  return button;
}
