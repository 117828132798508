"use client";
import { IoIosClose } from "react-icons/io";
import { BlackoutBackdrop } from "./BlackoutBackdrop";
import { useEffect } from "react";
import { DNA, Oval } from "react-loader-spinner";
import { Panel } from "./Panel";
import { prizes, rarityToColor } from "./config";
import { Profile as ProfileType } from "../logic/websocket/types";
import { locale } from "../logic/locale";
import websocket from "../logic/websocket";

interface Props {
  profile: ProfileType;
}

const previousPosition = {
  bodyScrollTop: 0,
  documentElementScrollTop: 0,
};

export function Profile(props: Props) {
  const { profile } = props;

  useEffect(() => {
    previousPosition.bodyScrollTop = document.body.scrollTop;
    previousPosition.documentElementScrollTop =
      document.documentElement.scrollTop;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    return () => {
      document.body.scrollTop = previousPosition.bodyScrollTop;
      document.documentElement.scrollTop =
        previousPosition.documentElementScrollTop;
    };
  }, []);

  useEffect(() => {
    console.log(profile);
  }, [profile]);

  const onClose = () => {
    websocket.openedProfile = null;
    websocket.updateOpenedProfile();
  };

  return (
    <Panel onClose={onClose}>
      <p className="text-2xl font-medium text-grey-title">
        {profile.user.username}
      </p>
      {profile.isLoading ? (
        <div className="flex w-full h-full flex-col items-center pt-32">
          <DNA
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      ) : (
        <>
          {/* TOTAL PROFIT */}
          <p className="text-md font-medium text-white mt-0 text-start w-full mt-1">
            <span className="text-grey-title">
              {locale.texts.accountTotalBets}:{" "}
            </span>
            {profile.totalBets?.toFixed(2)} $PUNK
          </p>
          <p className="text-md font-medium text-white mt-0 text-start w-full">
            <span className="text-grey-title">
              {locale.texts.accountTotalWins}:{" "}
            </span>
            {profile.totalProfit?.toFixed(2)} $PUNK
          </p>
          <p className="text-md font-medium text-white mt-0 text-start w-full">
            <span className="text-grey-title">
              {locale.texts.accountBetsCount}:{" "}
            </span>
            {profile.betsCount}
          </p>
          {websocket.user?.id === profile.user.id && (
            <>
              <div className="flex flex-col w-full mt-1 bg-grey-black rounded-xl justify-center py-2 pb-4 items-center gap-2 border border-grey-border">
                <span className="text-grey-title">
                  Rockets {"(← scroll →)"}
                </span>
                <div className="flex flex-row w-full overflow-x-scroll gap-2 removeScrollbarClass px-2">
                  {profile.rockets?.length &&
                    profile?.rockets.map((rocket, index) => {
                      const prize = prizes[rocket.item_id];
                      const colors = rarityToColor[prize.rarity];

                      if (!prize) return;

                      return (
                        <button
                          key={index}
                          onClick={() => {
                            websocket.selectRocket(rocket.item_id);
                          }}
                          className={
                            "relative p-2 shrink-0 w-28 h-36 flex flex-col rounded-xl items-center justify-center "
                          }
                        >
                          <div
                            className={`absolute w-full h-full rounded-xl opacity-100 `}
                            style={{
                              background: `radial-gradient(circle at center, ${colors.f}, ${colors.s})`,
                              boxShadow:
                                rocket.item_id === profile.rocketId
                                  ? `inset 0 0 10px rgba(255, 255, 255, 0.5)`
                                  : "",
                            }}
                          />
                          <img
                            src={`/assets${prize.image}`}
                            alt="rocket"
                            className="h-20 object-contain z-50 mt-4"
                          />
                          {profile.selectingRocket === rocket.item_id && (
                            <div className="absolute w-full h-full flex items-center justify-center text-white bg-grey-black opacity-30 rounded-xl z-50">
                              loading...
                            </div>
                          )}
                          <p
                            className="text-xs font-semibold mt-1 z-50"
                            style={{
                              color: prize.textColor
                                ? prize.textColor
                                : "white",
                            }}
                          >
                            {prize.name}
                          </p>
                          <p
                            className="text-xs z-50 font-semibold"
                            style={{ color: "#ddd" }}
                          >
                            {rocket.item_id === profile.rocketId
                              ? "Selected"
                              : "Select"}
                          </p>
                        </button>
                      );
                    })}
                </div>
              </div>
              <div className="flex flex-col w-full bg-grey-black rounded-xl justify-center py-2 pb-4 items-center gap-2 border border-grey-border">
                <span className="text-grey-title">
                  Username Colors {"(← scroll →)"}
                </span>
                <div className="flex flex-row w-full overflow-x-scroll gap-2 removeScrollbarClass px-2">
                  {profile.nicknames?.length &&
                    profile?.nicknames.map((nickname, index) => {
                      const prize = prizes[nickname.item_id];
                      return (
                        <button
                          key={index}
                          onClick={() => {
                            websocket.selectNickname(nickname.item_id);
                          }}
                          className={
                            "relative p-2 shrink-0 w-28 h-14 flex flex-col rounded-xl items-center justify-center"
                          }
                        >
                          <div
                            className={`absolute w-full h-full rounded-xl opacity-100 bg-grey-dark border border-grey-border`}
                            style={{
                              boxShadow:
                                nickname.item_id === profile.nicknameId
                                  ? `inset 0 0 10px rgba(255, 255, 255, 0.5)`
                                  : "",
                            }}
                          />
                          <div
                            className={`text-sm z-50`}
                            style={{ color: prize.color }}
                          >
                            @username
                          </div>
                          {profile.selectingNickname === nickname.item_id && (
                            <div className="absolute w-full h-full flex items-center justify-center text-white bg-grey-black opacity-30 rounded-xl z-50">
                              loading...
                            </div>
                          )}
                          <p className="text-sm text-white z-50">
                            {nickname.item_id === profile.nicknameId
                              ? "Selected"
                              : "Select"}
                          </p>
                        </button>
                      );
                    })}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Panel>
  );
}
