import { useEffect, useState } from "react";
import websocket from "../logic/websocket";

let rouletteUrls = [
  "http://localhost:3001",
  "https://rocket-wheel.vercel.app",
  "https://roulette.pfplabs.xyz",
];
interface Props {
  setIframe: (value: boolean) => void;
}
export function RouletteIframe(props: Props) {
  const { setIframe } = props;

  const [url, setUrl] = useState<string>();

  useEffect(() => {
    setUrl(websocket.rouletteUrl);
    websocket.setRouletteUrl = setUrl;

    const handleMessage = (event: any) => {
      if (event.data.event === "close") setIframe(false);
    };

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    document.body.style.overflow = "hidden";
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (url) {
    window.open(url, "_self");
    }
  }, [url]);

  return (
    <div className="absolute top-0 left-0 w-screen h-screen z-50 bg-gradient-to-b from-purple-light to-black">
      {!url && (
        <div
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-md font-light"
          style={{ color: "#fff" }}
        >
          Loading...
        </div>
      )}
      {url && (
        <iframe
          src={url}
          frameBorder="0"
          className="absolute top-0 left-0 w-full h-full"
        />
      )}
    </div>
  );
}
