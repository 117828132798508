import { Title } from "./Deposit";

export function Account() {

  return (
    <div className="flex flex-col items-center gap-2 w-full">
      <Title title="Registered: 24 Dec 2023" />
      <div className="flex flex-col ">
        {/* <div className="flex flex-row text-lg text-white">
          Daily Profit:<span className="text-green ml-1">+10.00$</span>
        </div>
        <div className="flex flex-row text-lg text-white">
          Total Profit:<span className="text-red ml-1">-123.34$</span>
        </div>
        <div className="flex flex-row text-lg text-white">Total Bets: 1004</div> */}
      </div>
    </div>
  );
}
