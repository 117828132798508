import { useEffect, useRef, useState } from "react";
import { DEFAULT_NICKNAME, DEFAULT_ROCKET, prizes } from "../config";
import { Nickname } from "../Nickname";
import websocket from "../../logic/websocket";
import { Message as MessageType } from "../../logic/websocket/types";
import { locale } from "../../logic/locale";

interface Props {
  setIsFullChat: (flag: boolean) => void;
  isFullChat: boolean;
  isDesktop: boolean;
  size: {
    width: number;
    height: number;
  };
  setIsSelectLanguageOpen: (flag: boolean) => void;
  chatLanguage: string;
}

const languages = [
  "arabic",
  "fr",
  "indonesia",
  "iran",
  "philippines",
  "ru",
  "en",
  "uk",
  "uz",
];
const names: any = {
  arabic: "العربية",
  fr: "Français",
  indonesia: "Indonesia",
  iran: "فارسی",
  philippines: "Filipino",
  ru: "Русский",
  en: "English",
  uk: "Українська",
  uz: "O'zbek",
};

export var firstTimeUpdate = {
  flag: true,
  isLoading: true,
};

export function Chat(props: Props) {
  const { setIsFullChat, isFullChat, isDesktop, size } = props;
  const [message, setMessage] = useState("");

  let [messages, setMessages] = useState<MessageType[]>([]);
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    websocket.setMessages = setMessages;
    websocket.updateMessages();

    return () => {
      firstTimeUpdate.flag = true;
    };
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      if (messages.length > 0 && firstTimeUpdate) {
        firstTimeUpdate.flag = false;
        ref.current.scrollTop = ref.current.scrollHeight;
        setIsLoading(firstTimeUpdate.isLoading);
      } else if (messages.length === 0) {
        setIsLoading(firstTimeUpdate.isLoading);
      }

      if (
        !firstTimeUpdate.flag &&
        ref.current.scrollTop + ref.current.clientHeight + 100 <
          ref.current.scrollHeight
      ) {
        // make it a little bit hire of message height
        ref.current.scrollTop = ref.current.scrollTop - 1;
        return;
      }

      if (messages.length > 0 && firstTimeUpdate) {
        firstTimeUpdate.flag = false;
        setIsLoading(false);
      }
    }
  }, [messages]);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (message.length > 0) {
        websocket.sendMessage(message);

        setMessage("");
      } else {
        alert("Message length should be more than 0 and less than 300");
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  return (
    <div className="flex flex-col h-max w-full bg-grey-black rounded-xl border-grey-border border relative">
      {!isLoading && (
        <button
          className={`absolute right-2 ${
            isDesktop ? "top-2" : "top-9"
          } px-2 py-1 bg-grey-dark rounded-3xl`}
          onClick={() => {
            props.setIsSelectLanguageOpen(true);
          }}
        >
          <div className="flex items-center gap-2">
            <img src={`/flags/${props.chatLanguage}.svg`} className="h-6 w-6" />
            <img
              src="/arrow_drop_up.svg"
              className="h-5 w-5"
              style={{
                transform: "rotate(180deg)",
                marginLeft: -5,
                marginRight: -5,
                filter: "invert(1)",
              }}
            />
          </div>
        </button>
      )}
      {!isDesktop && (
        <button
          onClick={() => {
            setIsFullChat(!isFullChat);
          }}
          className="h-7 w-full bg-grey-mid rounded-t-xl flex items-center justify-center gap-2 text-grey-black font-bold"
        >
          <img
            src="/arrow_drop_up.svg"
            className={"h-7 w-7 " + (isFullChat ? "rotate-180" : "")}
          />
          <div>
            {isFullChat ? locale.texts.chatShowLess : locale.texts.chatShowMore}
          </div>
          <img
            src="/arrow_drop_up.svg"
            className={"h-7 w-7 " + (isFullChat ? "rotate-180" : "")}
          />
        </button>
      )}

      <div
        id="removeScrollbar"
        className={
          (!isDesktop && (isFullChat ? "bigscrollchat " : " scrollchat ")) +
          "flex flex-col flex-1 overflow-y-scroll"
        }
        style={{
          maxHeight: isDesktop ? size.height - 100 : "",
        }}
        ref={ref}
      >
        {isLoading && (
          <div className="flex w-full h-full flex-col justify-center items-center p-10 text-white font-bold text-sm">
            Loading...
          </div>
        )}
        {messages.map((message, index) => {
          return <Message message={message} index={index} key={index} />;
        })}

        <input
          value={message}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          type="text"
          className="p-2 h-10 text-white bg-grey-black outline-none border-t border-grey-border rounded-b-xl w-full"
          placeholder={locale.texts.chatPlaceholder}
        />
      </div>
    </div>
  );
}

function Message(props: { message: MessageType; index: number }) {
  const { message, index } = props;

  const localTime = new Date(message.created_at).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  });

  return (
    <div key={index} className="px-2">
      <span className="text-sm text-grey-title mr-1 float-start h-6 leading-6">
        {localTime}
      </span>
      <div className="inline-block h-6 overflow-hidden float-start">
        <Nickname
          user={message.user}
          doubleDot={true}
          onClick={() => {
            websocket.getProfile(message.user.id, message.user.username);
          }}
        />
      </div>

      <span className="text-md text-white mr-2">{message.text}</span>
    </div>
  );
}
