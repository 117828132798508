export function TokenIcon(props: {
  src: string;
  mt?: number;
  height?: string;
  mx?: number;
  ml?: number;
}) {
  const { src, height } = props;
  const h = height ? height : "21px";
  const mx = props.mx ? props.mx : 1;
  const ml = props.ml ? props.ml : 0;

  return (
    <img
      className={`  mx-${mx}
     object-contain`}
      src={src}
      style={{ marginTop: props.mt, height: h, marginLeft: ml }}
    />
  );
}
