import { useEffect, useState } from "react";
import { Input } from "../Input";
import { TokenIcon } from "../Menu/TokenIcon";
import { DNA } from "react-loader-spinner";
import { Nickname } from "../Nickname";
import websocket from "../../logic/websocket";
import { locale } from "../../logic/locale";
import { Leaderboard as LeaderboardType } from "../../logic/websocket/types";

export function Leaderboard() {
  let [leaderboard, setLeaderboard] = useState<LeaderboardType>(
    websocket.leaderboard
  );

  useEffect(() => {
    websocket.getTopEarningUsers("profit", "desc", "all");
    websocket.setLeaderboard = setLeaderboard;

    if (websocket.leaderboard) {
      setLeaderboard(websocket.leaderboard);
      if (websocket.leaderboard.leaderboard.length === 0) {
        websocket.getTopEarningUsers("profit", "desc", "all");
      }
    }
  }, []);

  return (
    <>
      <Dropdown value={leaderboard.sort_period} leaderboard={leaderboard} />
      <div
        id="removeScrollbarLeaderboard"
        className="h-max w-full flex flex-col bg-grey-black rounded-xl text-sm font-bold text-white min-h-10 overflow-scroll border-grey-border border gap-x-0.5"
      >
        <div className="flex flex-row w-full justify-between bg-grey-dark p-1 border-b border-grey-border">
          <div className="text-start opacity-50" style={{ width: 16 }}>
            n
          </div>
          <div className="text-start opacity-50" style={{ width: 80 }}>
            {locale.texts.leaderboardPlayer}
          </div>
          <div className="text-start flex flex-row" style={{ width: 96 }}>
            {ColumnName(
              "wagered",
              leaderboard.sort_column,
              leaderboard.sort_order,
              leaderboard.sort_period
            )}
          </div>
          <div className="text-start flex flex-row" style={{ width: 80 }}>
            {ColumnName(
              "profit",
              leaderboard.sort_column,
              leaderboard.sort_order,
              leaderboard.sort_period
            )}
          </div>
          <div className="text-start flex-row flex" style={{ width: 48 }}>
            {ColumnName(
              "bets_count",
              leaderboard.sort_column,
              leaderboard.sort_order,
              leaderboard.sort_period
            )}
          </div>
        </div>
        {leaderboard.isLoading && (
          <div className="flex w-full flex-col items-center">
            <DNA
              visible={true}
              height="50"
              width="50"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
        {leaderboard?.leaderboard.map((data, index) => {
          return (
            <div
              key={index}
              className={
                "flex flex-row justify-between text-sm font-medium p-1" +
                (index % 2 === 1 ? " bg-grey-dark" : "")
              }
            >
              <div
                className={
                  "text-start " + (index < 3 ? "text-green" : "text-white")
                }
                style={{ width: 16 }}
              >
                {index + 1}
              </div>
              <Nickname
                user={{
                  username: data.username,
                  rocket_id: data.rocket_id,
                  nickname_id: data.nickname_id,
                }}
                className={"max-w-16 text-left"}
                onClick={() => {
                  websocket.getProfile(data.user_id, data.username);
                }}
              />
              <div
                className="text-start w-max flex-row flex"
                style={{ width: 96 }}
              >
                {data.wagered.toFixed(2)}
                <TokenIcon src="punk-token.png" mt={2} mx={0.5} height="14px" />
              </div>

              <div
                className="text-start w-max flex-row flex"
                style={{ width: 80 }}
              >
                {data.profit.toFixed(2)}
                <TokenIcon src="punk-token.png" mt={2} mx={0.5} height="14px" />
              </div>
              <div className="text-start w-max" style={{ width: 48 }}>
                {data.bets_count}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

function ColumnName(
  column: string,
  sort_column: string,
  sort_order: string,
  sort_period: string
) {
  let text: string = "";

  switch (column) {
    case "wagered":
      text = locale.texts.leaderboardWagered;
      break;
    case "profit":
      text = locale.texts.leaderboardProfit;
      break;
    case "bets_count":
      text = locale.texts.leaderboardBets;
      break;
  }

  return (
    <>
      <span
        className={`cursor-pointer ${
          column === sort_column ? "" : "opacity-50"
        }`}
        onClick={() => {
          websocket.getTopEarningUsers(
            column,
            column === sort_column
              ? sort_order === "asc"
                ? "desc"
                : "asc"
              : "desc",
            sort_period
          );
        }}
      >
        {text}
      </span>
      {column === sort_column && (
        <img
          src="/arrow_drop_up.svg"
          className="h-5 w-5 ml-1"
          style={{
            filter: `invert(1)`,
            transform: `rotate(${sort_order === "asc" ? 0 : 180}deg)`,
          }}
        />
      )}
    </>
  );
}

// for all time/day/week
function Dropdown(props: { value: string; leaderboard: LeaderboardType }) {
  return (
    <div className="w-max bg-grey-dark text-white rounded-3xl w-32 border-grey-border border p-0.5 px-1 mb-2">
      <select
        value={props.value}
        onChange={(e) => {
          websocket.getTopEarningUsers(
            props.leaderboard.sort_column,
            props.leaderboard.sort_order,
            e.target.value as "all" | "day" | "week"
          );
        }}
        className="bg-grey-dark text-white rounded-3xl w-24 text-sm font-bold outline-none"
      >
        <option value="all">{locale.texts.leaderboardAllTime}</option>
        <option value="day">{locale.texts.leaderboardDay}</option>
        <option value="week">{locale.texts.leaderboardWeek}</option>
      </select>
    </div>
  );
}
