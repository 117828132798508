import { LocaleType } from ".";

export const enTexts: LocaleType = {
        buttonBet: "Bet",
        buttonBetNextRound: "Bet (Next Round)",
        cashout: "Cashout",
        loading: "Loading...",
        cancel: "Cancel",
        betAmount: "bet amount",
        autoCashoutAt: "auto cashout at",
        targetProfit: "Target Profit",
        chat: "Chat",
        chatShowMore: "Show More",
        chatShowLess: "Show Less",
        history: "History",
        leaderboard: "Leaderboard",
        players: "Players",
        chatPlaceholder: "Type your message...",
        playersOnline: "Online",
        playersPlaying: "Playing",
        playersNoPlaying: "No Playing Users",
        historyBust: "Bust",
        historyProfit: "Profit",
        historyHash: "Hash",
        leaderboardPlayer: "Player",
        leaderboardWagered: "Wagered",
        leaderboardProfit: "Profit",
        leaderboardBets: "Bets",
        leaderboardDay: "Day",
        leaderboardWeek: "Week",
        leaderboardAllTime: "All Time",

        accountTotalBets: "Total Bets",
        accountTotalWins: "Total Wins",
        accountBetsCount: "Bets Count",

        depositTitle: "Deposit",
        depositText: "Your $PUNK Balance Connected To Punk City Wallet",
        depositButton: "Top Up Punk City Wallet",
        depositButtonTonkeeper: "Pay With Tonkeeper",
        depositNav: "Deposit",

        withdrawTitle: "Withdraw",
        withdrawText: "To Withdraw $PUNK Use Punk City Wallet",
        withdrawButton: "Open Punk City Wallet",
        withdrawNav: "Withdraw",

        gameStarting: "Starting in",

        winChance: "Win Chance",

        manual: "manual",
        auto: "auto",
        startAutobet: "Start Autobet",
        stopAutobet: "Stop Autobet",
}