import websocket from "../../../logic/websocket";
import { growthFunc } from "../../logic/lib";
import { MainScene } from "../MainScene";

var OX_START = 1000;
var OY_START = 1;

const ml = 20;
const mr = 20;
const mt = 30;
const mb = 10;

var maxRight = 0;

let stepX = 5000;
let stepY = 0.1;

export const clearGraph = async (scene: MainScene) => {
  scene.path?.destroy?.();
  scene.xTexts?.forEach((text) => text?.destroy?.());
  scene.yTexts?.forEach((text) => text?.destroy?.());
};

export const drawGraph = async (scene: MainScene, time: number) => {
  const width = scene.sys.game.canvas.width - mr;
  const height = scene.sys.game.canvas.height - mb;

  clearGraph(scene);

  var currentPayout = growthFunc(time) - 1;

  var ox = Math.max(time, OX_START);

  var oy = Math.max(currentPayout, OY_START);

  scene.path = scene.add.graphics().setDepth(-1);
  // split # and convert to hex
  scene.path.lineStyle(1, parseInt(scene.strokeColor.replace("#", "0x")), 0.5);

  /* Draw the graph */
  for (var t = 0, i = 0; t <= time; t += 100, i++) {
    /* Graph */
    var payout = growthFunc(t) - 1;
    var y = height - (payout / oy) * (height - mt);
    var x = (t / ox) * (width - ml);

    scene.path.lineTo(x + ml, y);

    let previousPayout = growthFunc(t - 100) - 1;
    let previousY = height - (previousPayout / oy) * (height - mt);
    let previousX = ((t - 100) / ox) * (width - ml);

    scene.path.fillStyle(parseInt(scene.strokeColor.replace("#", "0x")), 0.2 * t/time)

    if (!maxRight) maxRight = previousX;

    scene.path.fillRect(
      previousX + ml,
      previousY,
      width - previousX - ml,
      y - previousY
    );

    if (i > 5000) {
      break;
    }
  }
  scene.path.stroke();

  // // write steps
  // ctx.font = '12px "Wix Madefor Text", sans-serif';
  // ctx.font = "medium " + ctx.font;
  // ctx.fillStyle = "rgba(255, 255, 255, 0.2)";
  // ctx.textAlign = "right";

  // ctx.lineWidth = 1;

  // calculate steps
  if (oy / stepY > 5) {
    stepY *= 2;
    scene.yTexts = [];
  }

  for (let y = 0; y < oy; y += stepY) {
    let yCoord = height - (y / oy) * (height - mt);
    scene.yTexts.push(
      scene.add.text(width + 60, yCoord, (y + 1).toFixed(2) + "x", {
        fontSize: "12px",
        color: "#ffffff",
      })
    );
  }

  // if (ox / stepX > 5) {
  //   stepX *= 2;
  // }

  // for (let x = 0; x < ox; x += stepX) {
  //   let xCoord = (x / ox) * (width - ml);
  //   // ctx.fillText((x / 1000).toFixed(0), xCoord + ml, height + 15);
  //   scene.texts.push(scene.add.text(xCoord + ml, height + 15, (x / 1000).toFixed(0), { fontSize: "12px", color: "#ffffff" }));
  // }

  updateRocket(scene, time, height, width, ox, oy);
};

export async function updateRocket(
  scene: MainScene,
  time: number,
  height: number,
  width: number,
  ox: number,
  oy: number
) {
  scene.rocketGroup?.setAlpha(1);
  if (time - 100 <= 0) return;

  const payout1 = growthFunc(time - 100) - 1;
  const payout2 = growthFunc(time) - 1;
  const y1 = height - (payout1 / oy) * (height - mt);
  const y2 = height - (payout2 / oy) * (height - mt);
  const x1 = ((time - 100) / ox) * (width - ml);
  const x2 = (time / ox) * (width - ml);

  if (
    scene.rocketGroup &&
    scene.groupRotation !== undefined &&
    scene.rocketGroupPosition &&
    scene.rocketGroup?.children?.entries
  ) {
    const rotation = Math.atan2(y2 - y1, x2 - x1) + Math.PI / 2;

    if (rotation && rotation <= Math.PI && rotation >= -Math.PI / 4 && rotation <= scene.groupRotation) {
      scene.rocketGroup.rotate(-scene.groupRotation + rotation); // 1 || 2
      websocket.mainScene!.groupRotation = rotation;
    }

    const rocketX = x2 + ml;
    const rocketY = y2;

    scene.rocketGroup.setXY(rocketX, rocketY);
    websocket.mainScene!.rocketGroupPosition = { x: rocketX, y: rocketY };
  }
}
