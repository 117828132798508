import { Scene } from "phaser";

export class PreloadScene extends Scene {
  
    constructor() {
      super("PreloadScene");
    }

    preload() {
        
    }

    create() {
        // this.scene.start('MainScene');
    }


}