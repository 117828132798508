import { enTexts } from "./en";
import { ruTexts } from "./ru";

export interface LocaleType {
    buttonBet: string;
    buttonBetNextRound: string;
    cashout: string;
    loading: string;
    cancel: string;
    betAmount: string;
    autoCashoutAt: string;
    targetProfit: string;
    chat: string;
    history: string;
    leaderboard: string;
    players: string;
    chatPlaceholder: string;
    chatShowMore: string;
    chatShowLess: string;
    playersOnline: string;
    playersPlaying: string;
    playersNoPlaying: string;
    historyBust: string;
    historyProfit: string;
    historyHash: string;
    leaderboardPlayer: string;
    leaderboardWagered: string;
    leaderboardProfit: string;
    leaderboardBets: string;
    leaderboardDay: string;
    leaderboardWeek: string;
    leaderboardAllTime: string;

    accountTotalBets: string;
    accountTotalWins: string;
    accountBetsCount: string;

    depositTitle: string;
    depositText: string;
    depositButton: string;
    depositNav: string;
    depositButtonTonkeeper: string;

    withdrawTitle: string;
    withdrawText: string;
    withdrawButton: string;
    withdrawNav: string;

    gameStarting: string;

    winChance: string;

    manual: string;
    auto: string;
    startAutobet: string;
    stopAutobet: string;
}

class Locale {
  locale: "en" | "ru" = "en";
  
  texts: LocaleType = enTexts;

  updateLocale(locale: "en" | "ru") {
    this.locale = locale;
    switch (locale) {
      case "ru":
        this.texts = ruTexts;
        break;
      default:
        this.texts = enTexts;
        break;
    }
  }
}

export const locale = new Locale();
