import {
  DEFAULT_NICKNAME,
  DEFAULT_ROCKET,
  prizes,
  rarityToColor,
} from "./config";

interface Props {
  user: {
    username: string;
    rocket_id: number | null;
    nickname_id: number | null;
  };
  onClick: () => void;
  className?: string;
  doubleDot?: boolean;
}

export function Nickname(props: Props) {
  const { user, onClick, className, doubleDot } = props;

  let rocketId = user.rocket_id;
  if (!rocketId) rocketId = DEFAULT_ROCKET;
  let nicknameId = user.nickname_id;
  if (!nicknameId) nicknameId = DEFAULT_NICKNAME;

  let color = prizes[nicknameId].color;
  let rocketImage = prizes[rocketId].image;
  let rocketRarity = prizes[rocketId].rarity;

  return (
    <div className={"flex flex-row relative cursor-pointer h-6 justify-end"}>
      <div
        className={`absolute left-1 top-1 w-4 h-4 rounded-full opacity-30`}
        style={{ backgroundColor: rarityToColor[rocketRarity].f }}
      ></div>
      <img
        src={`/assets${rocketImage}`}
        className="h-6 w-2 object-contain mr-2 ml-2"
        style={{ transform: "rotate(35deg)" }}
      />

      <span
        className={
          "cursor-pointer text-md mr-2 text-ellipsis whitespace-nowrap overflow-hidden " +
          className +
          " " +
          (nicknameId === 3 && " fuck")
        }
        style={{ color }}
        onClick={onClick}
      >
        {user.username}
        {doubleDot && ":"}
      </span>
    </div>
  );
}
