interface Props {
  onClick?: () => void;
}
export function BlackoutBackdrop(props: Props) {
  const { onClick } = props;
  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      className="z-10 fixed top-0 left-0 w-full h-full overflow-hidden"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
    ></div>
  );
}
