var r = 0.00006;

export function growthFunc(ms:number) {
    if (ms < 0) return 1;

    return Math.floor(100 * Math.pow(Math.E, r * ms)) / 100;
}

export function angleOfGrowth(ms: number) {
    var slope = growthFunc(ms);

    return -Math.atan(slope) * (180 / Math.PI) + 90 + 45;
}