import { MainScene } from "../MainScene";

export function createFireParticles(scene: MainScene) {
  // const particles = scene.add.particles(-100, -100, "particle", {
  //   quantity: 5,
  //   x: { min: -1, max: 5 },
  //   y: 115,
  //   color: [0xffcc99, 0xff6633],
  //   alpha: { start: 1, end: 0 },
  //   angle: { min: 80, max: 100 },
  //   lifespan: 500,
  //   speed: 300,
  //   scale: { start: 0.25, end: 0.3 },
  // });

  // return particles;

  const effect = scene.add
    .sprite(-200, -200, "blue-fire")
    .setAngle(90)
    .setOrigin(-0.43, 0.495);

  // animation from 0 to 15
  if (!scene.anims.get("blue-fire")) {
    scene.anims.create({
      key: "blue-fire",
      frames: scene.anims.generateFrameNumbers("blue-fire", {
        start: 1,
        end: 15,
      }),
      frameRate: 20,
      repeat: -1,
    });
  }

  effect.play("blue-fire");

  return effect;
}

export function createBoomEffect(scene: MainScene, x: number, y: number) {
  const boom = scene.add.sprite(x, y, "boom-effect").setScale(1.5);

  if (!scene.anims.get("boom-effect")) {
    scene.anims.create({
      key: "boom-effect",
      frames: scene.anims.generateFrameNumbers("boom-effect", {
        start: 0,
        end: 9,
      }),
      frameRate: 20,
      repeat: 0,
    });
  }

  boom.play("boom-effect");

  setTimeout(() => {
    boom.destroy();
  }, 500);
}
