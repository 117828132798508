import { Scene, GameObjects, Cameras, Renderer } from "phaser";
import { angleOfGrowth, growthFunc } from "../logic/lib";
import timer from "../logic/timer";
import { animateBackground, createBackground } from "./Services/Background";
import { createBoomEffect, createFireParticles } from "./Services/Effects";
import { createRocketWithFire, updateRocketTexture } from "./Services/Rocket";
import { getRocketData } from "./Services/utils";
import { clearGraph, drawGraph, updateRocket } from "./Services/Graph";
import websocket, { setText, setUserBetText } from "../../logic/websocket";
import { GameData } from "../../logic/websocket/types";
import { DEFAULT_NICKNAME, prizes, rarityToColor } from "../../components/config";

export let setIsGameReadyMainScene: any = {};

const rockets = [
  "99999999991-blue.svg",
  "5-blue.svg",
  "6-blue.svg",
  "7-blue.svg",
  "8-purple.svg",
  "9-purple.svg",
  "21-purple.svg",
  "16-pink.svg",
  "17-pink.svg",
  "20-gold.svg",
  "18-red.svg",
  "19-red.svg",
];

export class MainScene extends Scene {
  text?: GameObjects.Text;
  usernameText?: GameObjects.DOMElement;
  bgRocket?: GameObjects.Image;
  groupRotation: number = 0;

  /**
   * FOR UPDATE GRAPH
   */
  path?: GameObjects.Graphics;
  elapsed: number = 0;
  needToClear: boolean = false;
  needToStart: boolean = false;
  isStarted: boolean = false;
  xTexts: GameObjects.Text[] = [];
  yTexts: GameObjects.Text[] = [];
  strokeColor: string = "#fff";

  constructor() {
    super("MainScene");
  }

  preload() {
    this.load.image("stars-small", "assets/stars-small.png");

    this.load.image("stars-big", "assets/stars-big.png");

    this.load.image("rocket-light", "assets/rockets/light.png");

    this.load.spritesheet("boom-effect", "assets/boom-effect.png", {
      frameWidth: 100,
      frameHeight: 100,
    });
    this.load.spritesheet("blue-fire", "assets/blue-fire.png", {
      frameWidth: 160,
      frameHeight: 160,
    });
  }

  init() {}

  spaceBack?: GameObjects.TileSprite;
  starsSmall?: GameObjects.TileSprite;
  starsBig?: GameObjects.TileSprite;

  angle?: number;

  rocketGroup?: GameObjects.Group;
  rocketGroupPosition?: { x: number; y: number } = {
    x: 0,
    y: 0,
  };
  rocket?: GameObjects.DOMElement;

  create() {
    let { width, height } = this.sys.game.canvas;
    this.groupRotation = 0;

    createBackground(this, width, height);

    createRocketWithFire(this);

    let length = 0;

    if (setIsGameReadyMainScene.func) setIsGameReadyMainScene.func(true);

    this.setupGame();

    websocket.mainScene = this;

    // setTimeout(() => {
    //   this.onStart();
    // }, 500);
  }

  setupGame() {
    try {
      const currentGame = websocket.currentGame;
      if (!currentGame) return;

      if (currentGame.isPaused) {
        setUserBetText("Check Out Channel", { color: "#fff" });
        setText("PAUSED", { color: "#fff" });
        return;
      }

      switch (currentGame.state) {
        case "IN_PROGRESS":
          this.startGame();
          break;
        case "ENDED":
          this.crashGame(currentGame);
          break;
        case "STARTING":
          this.prepareGame();
          break;
      }
    } catch (e) {
      console.log(e);
    }
  }

  prepareGame() {
    if (!websocket.mainScene) return;

    try {
      this.needToClear = true;
      this.needToStart = false;
      this.isStarted = false;
      this.elapsed = 0;

      setUserBetText("", { hide: true, color: "#DB3031" });

      timer.start(
        (ms: number) => {
          let text = "Starting Soon";

          if (5 - ms / 1000 > 0) {
            text = "Starting in " + (5 - ms / 1000).toFixed(2) + "s";
          }

          setText(text, {
            fontSize: 20,
            marginTop: -25,
          });
        },
        () => {}
      );

      this.updateCaptain();
    } catch (e) {
      console.log(e);
    }
  }

  startGame() {
    try {
      if (!this.rocketGroup || !this.rocketGroup?.getChildren()?.entries)
        return;

      updateRocketTexture(this, { addUsername: true });
      this.onStart();
    } catch (e) {
      console.log(e);
    }
  }

  updateCaptain() {
    if (!this.rocketGroup || !this.rocketGroup?.children?.entries) return;
    if (websocket.currentGame?.state !== "STARTING") return;

    const data = getRocketData();

    const width = this.sys.game.canvas.width;

    this.rocketGroup.setAlpha(1);
    this.rocketGroup.setXY(width / 2, 100);

    const newRotation = 0;
    this.rocketGroup.rotate(-this.groupRotation + newRotation);
    this.groupRotation = newRotation;

    this.rocket = this.rocketGroup.getChildren()[0] as GameObjects.DOMElement;

    updateRocketTexture(this);

    if (data.username.length) {
      setUserBetText(`captain:`, {
        fontSize: 15,
        weight: "500",
        marginTop: -5,
        spanText: { text: `@${data.username}`, color: data.nickname.color },
      });
    }
  }

  crashGame(data: GameData) {
    this.needToClear = true;
    this.needToStart = false;
    this.isStarted = false;
    this.elapsed = 0;
    timer.stop();

    if (!websocket.crashData) return;

    if (!this.rocketGroup || !this.rocketGroup?.getChildren()?.entries) return;

    const rData = getRocketData();
    const colors = rarityToColor[rData.rocket.rarity];
    const textRgba = colors.textRgba;

    this.rocketGroup?.setAlpha(0);

    if (!websocket.betStatus.cashedOut) {
      setUserBetText("CRASH", { color: "#A74D4D" });
    } else {
      setUserBetText("Cashed Out " + websocket.betStatus.cashedOut + "x", {
        color: textRgba || "#3E80FF",
      });
    }

    if (this.usernameText) {
      this.usernameText.node.innerHTML = "";
    }

    setText(
      websocket.crashData.game_crash === 0
        ? "1.00x"
        : (websocket.crashData.game_crash / 100).toFixed(2) + "x",
      { color: "#A74D4D" }
    );

    if (!this.rocketGroupPosition) return;
    createBoomEffect(
      this,
      this.rocketGroupPosition.x - 50,
      this.rocketGroupPosition.y
    );
  }

  onBet(data: {
    username: string;
    bet: number;
    rocket_id: number | null;
    nickname_id: number | null;
  }) {
    let { width, height } = this.sys.game.canvas;

    let x = Math.random() * 200 - 100 + width / 2;
    let y = Math.random() * 200 + height / 2;

    if (!this.scene.isActive()) {
      return;
    }

    const color = prizes[data.nickname_id || DEFAULT_NICKNAME].color;

    const text = this.add
      .text(x, y, "@" + data.username + " bet " + (data.bet / 1e9).toFixed(2), {
        fontSize: 16,
        color,
        fontStyle: "bold",
        fontFamily: '"Wix Madefor Text", sans-serif',
      })
      .setOrigin(0.5, 0.5);

    this.add.tween({
      targets: [text],
      y: y - 200,
      alpha: 0,
      duration: 2500,
      onComplete: () => {
        text.destroy();
      },
    });
  }

  onCashOut(data: {
    username: string;
    stoppedAt: number;
    bet: number;
    rocket_id: number | null;
    nickname_id: number | null;
  }) {
    // from rocket position
    if (!this.rocketGroupPosition) return;

    if (!this.scene.isActive()) {
      return;
    }

    const { width, height } = this.sys.game.canvas;

    let x = width + Math.random() * 200 - 100;
    let y = Math.random() * 200 - 100 + height / 2;

    const color = prizes[data.nickname_id || DEFAULT_NICKNAME].color;
    try {
      let text = this.add
        .text(
          x,
          y,
          "@" +
            data.username +
            " +" +
            ((data.stoppedAt * data.bet) / 1e9).toFixed(2),
          {
            fontSize: 16,
            color,
            fontStyle: "bold",
            fontFamily: '"Wix Madefor Text", sans-serif',
          }
        )
        .setOrigin(0.5, 0.5)
        .setDepth(-10);

      this.add.tween({
        targets: [text],
        x: 0,
        y: height,
        alpha: 0,
        duration: 2000,
        onComplete: () => {
          if (!text) return;
          text.destroy();
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  onEnd() {
    timer.stop();
  }

  onStart() {
    console.log("ON START", websocket.currentGame?.elapsed, this.elapsed);
    if (!this.rocketGroup || !this.rocketGroup?.getChildren()?.entries) return;

    this.rocketGroup.setAlpha(1);
    const newRotation = Math.PI / 2;
    this.rocketGroup.rotate(-this.groupRotation + newRotation);
    this.groupRotation = newRotation;
    this.rocketGroup.setXY(-200, -200);

    const data = getRocketData();
    const colors = rarityToColor[data.rocket.rarity];
    const textRgba = colors.textRgba;
    this.strokeColor = colors.f;

    this.elapsed = websocket.currentGame?.elapsed || 0;
    this.needToStart = true;

    timer.start(
      (time) => {
        let newY = growthFunc(time);
        setText(newY.toFixed(2) + "x");

        if (websocket.betStatus.amount && websocket.betStatus.isBetPlaying) {
          setUserBetText(
            "+" +
              (
                (websocket.betStatus.amount * newY -
                  websocket.betStatus.amount) /
                1e9
              ).toFixed(3),
            { color: textRgba || "#3E80FF" }
          );
        } else {
          if (websocket.betStatus.cashedOut) {
            setUserBetText(
              "Cashed Out " + websocket.betStatus.cashedOut + "x",
              {
                color: textRgba || "#3E80FF",
              }
            );
          } else {
            setUserBetText("", { hide: true, color: "#73E72F" });
          }
        }
      },
      (time) => {}
    );
  }

  update(time: number, delta: number) {
    animateBackground(this);

    if (!this.rocketGroup || !this.rocketGroup?.getChildren()?.entries) return;

    if (this.needToClear) {
      clearGraph(this);
      this.needToClear = false;
    }

    if (this.needToStart) {
      this.isStarted = true;
      this.needToStart = false;
    }

    if (this.isStarted) {
      this.elapsed += delta;
      drawGraph(this, this.elapsed);
    }
  }
}
