import { useEffect, useState } from "react";
import { TokenIcon } from "../Menu/TokenIcon";
import { ButtonSwitch } from "../ButtonSwitch";
import { Nickname } from "../Nickname";
import websocket from "../../logic/websocket";
import { Player } from "../../logic/websocket/types";
import { locale } from "../../logic/locale";

interface Props {
  isDesktop: boolean;
  size: {
    width: number;
    height: number;
  };
}

export function Players(props: Props) {
  const { isDesktop, size } = props;

  let [players, setPlayers] = useState<{ [id: string]: Player }>({});
  let [onlineUsers, setOnlineUsers] = useState<number>(0);
  useEffect(() => {
    websocket.setPlayers = setPlayers;
    websocket.setOnlineUsers = setOnlineUsers;
    websocket.updatePlayers();

    setOnlineUsers(websocket.onlineUsers);
  }, []);

  return isDesktop ? (
    <div
      className="absolute top-2 right-2 flex flex-col justify-center items-center"
      style={{
        width: (size.width - 512) / 2 - 18,
      }}
    >
      <ButtonSwitch
        names={["Players"]}
        selected="Players"
        h=" h-8"
        mt=" mt-1"
        mb=" mb-2"
      />
      <div className="w-full flex flex-col bg-grey-black rounded-xl text-sm text-white font-medium border-grey-border border">
        <div className="flex flex-row w-full justify-center bg-grey-dark rounded-t-xl p-1 gap-8 border-b border-grey-border">
          <div className="text-grey-title">
            {locale.texts.playersOnline}: {onlineUsers ? onlineUsers : 0}
          </div>
          <div className="text-grey-title">
            {locale.texts.playersPlaying}: {Object.keys(players).length}
          </div>
        </div>
        <div className="flex flex-col p-2 gap-1">
          {Object.keys(players).map((id, index) => {
            return (
              <PlayerRow key={index} id={id} index={index} players={players} />
            );
          })}
        </div>
        {Object.keys(players).length === 0 && (
          <div className="text-grey-title text-center pb-3">
            {locale.texts.playersNoPlaying}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="h-max w-full flex flex-col bg-grey-black rounded-xl text-sm text-white font-medium border-grey-border border">
      <div className="flex flex-row w-full justify-center bg-grey-dark rounded-t-xl p-1 gap-8 border-b border-grey-border">
        <div className="text-grey-title">
          {locale.texts.playersOnline}: {onlineUsers ? onlineUsers : 0}
        </div>
        <div className="text-grey-title">
          {locale.texts.playersPlaying}: {Object.keys(players).length}
        </div>
      </div>
      <div className="flex flex-col p-2 gap-1">
        {Object.keys(players).map((id, index) => {
          return (
            <PlayerRow key={index} id={id} index={index} players={players} />
          );
        })}
      </div>
      {Object.keys(players).length === 0 && (
        <div className="text-grey-title text-center pb-3">
          {locale.texts.playersNoPlaying}
        </div>
      )}
    </div>
  );
}

function PlayerRow(props: {
  id: string;
  index: number;
  players: { [id: string]: Player };
}) {
  const { id, index, players } = props;

  let betText = (players[id].bet / 1e9).toFixed(2);
  let betTextColor = "text-white";

  if (players[id].stopped_at) {
    betText =
      "+" +
      ((players[id].bet * players[id].stopped_at!) / 100 / 1e9).toFixed(2);
    betTextColor = "text-green";
  }

  return (
    <div key={index} className="flex flex-row w-full justify-between">
      <Nickname
        className="w-20 max-w-22"
        user={players[id]}
        onClick={() => {
          if (!players[id].username) return;
          if (isNaN(parseInt(id))) return;

          websocket.getProfile(parseInt(id), players[id].username);
        }}
      />
      <div className="w-4">
        {players[id].stopped_at
          ? (players[id].stopped_at! / 100).toFixed(2) + "x"
          : "-"}
      </div>
      <div className="flex flex-row">
        <div className={betTextColor}>{betText}</div>
        <TokenIcon height="15px" mt={1} src="punk-token.png" />{" "}
      </div>
    </div>
  );
}
