import { useEffect, useState } from "react";
import { Input } from "../Input";
import { TokenIcon } from "../Menu/TokenIcon";
import { TableHistory } from "../../logic/websocket/types";
import websocket from "../../logic/websocket";
import { locale } from "../../logic/locale";

export function History() {
  let [gameHistory, setGameHistory] = useState<TableHistory[]>();

  useEffect(() => {
    websocket.setGameHistory = setGameHistory;

    if (websocket.gameHistory) {
      setGameHistory(websocket.gameHistory);
    }
  }, []);

  return (
    <div className="h-full w-full flex flex-col bg-grey-black rounded-xl text-sm font-bold text-white p-2 gap-1 min-h-10 overflow-scroll border-grey-border border removeScrollbarClass">
      <div className="flex flex-row w-full justify-between">
        <div className="w-32 text-center">{locale.texts.historyBust}</div>
        <div className="w-16 text-center">{locale.texts.historyProfit}</div>
        <div className="w-32 text-center">{locale.texts.historyHash}</div>
      </div>
      {gameHistory?.map((data, index) => {

        return (
          <div
            key={index}
            className="flex flex-row items-center justify-between"
          >
            <div
              className={
                "w-32 text-center " +
                (data.game_crash > 200 ? "text-green" : "text-red")
              }
            >
              {data.game_crash !== 0 ? data.game_crash / 100 : "1.00"}
            </div>
            <div className="w-32 text-center w-max">{showProfit(data)}</div>
            <div className="w-32 h-12 text center">
              <Input isReadOnly={true} value={data.hash} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

function showProfit(data: TableHistory) {
  console.log(data);
  let id = websocket.user?.id;
  if (!id) return "-";
  if (!data || !data.player_info) return "-";

  let playerInfo = data.player_info[id];
  if (!playerInfo || !playerInfo.bet) return "-";

  let textColor = "text-red";
  let profitText = "";
  if (playerInfo.bet) {
    profitText = "-" + (playerInfo.bet / 1e9).toFixed(2) + " $PUNK";
    if (playerInfo.stopped_at) {
      textColor = "text-green";
      profitText =
        "+" + (((playerInfo.bet * playerInfo.stopped_at) / 100 - playerInfo.bet ) / 1e9).toFixed(2) + " $PUNK";
    }
  }

  return <div className={textColor}>{profitText}</div>;
}
