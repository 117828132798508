import server from "./server";

export async function handleAuth() {
  if (typeof window !== "undefined") {
    let initData = (window as any)?.Telegram?.WebApp?.initData;

    if (!initData) {
      initData = TEST_INIT_DATA;
    }

    // let data = await server.postAuth(initData);

    // return data;
  } else return null;
}

export const TEST_INIT_DATA =
  "asdkfajsdfklajsdlkf";
