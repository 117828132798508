import { useEffect, useState } from "react";
import { BlackoutBackdrop } from "./BlackoutBackdrop";
import { IoIosClose } from "react-icons/io";
import { ButtonSwitch } from "./ButtonSwitch";
import {
  FaMoneyBillTransfer,
  FaMoneyBillTrendUp,
  FaAddressCard,
} from "react-icons/fa6";
import { Deposit } from "./Menu/Deposit";
import { Withdraw } from "./Menu/Withdraw";
import { Account } from "./Menu/Account";
import websocket from "../logic/websocket";
import { locale } from "../logic/locale";

interface Props {
  startNav: string;
  onClose: () => void;
}
export function Menu(props: Props) {
  const { startNav, onClose } = props;

  let [nav, setNav] = useState(startNav);

  useEffect(() => {
    setNav(startNav);
  }, [startNav]);

  return (
    <>
      <BlackoutBackdrop onClick={onClose} />
      <div
        className="z-50 absolute top-1/2 left-1/2 bg-grey-dark p-6 -translate-x-1/2 -translate-y-1/2 rounded-3xl max-w-sm w-full"
        style={{ height: "31rem" }}
      >
        <button
          className="text-4xl absolute right-4 top-4 text-grey-title"
          onClick={onClose}
        >
          <IoIosClose />
        </button>
        <div className="flex flex-col h-full w-full items-center gap-2">
          <p className="text-2xl font-medium text-grey-title">
            {nav === "Account" && websocket.user?.username}
            {nav === "Deposit" && locale.texts.depositTitle}
            {nav === "Withdraw" && locale.texts.withdrawTitle}
          </p>
          {nav === "Deposit" && <Deposit />}
          {nav === "Withdraw" && <Withdraw />}
          {nav === "Account" && <Account />}
        </div>

        <div className="absolute bottom-0 left-0 w-full h-16 flex flex-row rounded-b-lg overflow-hidden bg-grey-black justify-center gap-10">
          {["Deposit", "Withdraw"].map((name, index) => {
            return (
              <MenuButton
                name={name}
                key={index}
                nav={nav}
                onClick={() => setNav(name)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

function MenuButton(props: {
  name: string;
  key: number;
  nav: string;
  onClick: () => void;
}) {
  let { name, key, nav, onClick } = props;
  let opacity = name === nav ? " none" : " opacity-30";
  let text: string = "";
  switch (name) {
    case "Deposit":
      text = locale.texts.depositNav;
      break;
    case "Withdraw":
      text = locale.texts.withdrawNav;
      break;
  }

  let icon = (
    <img src="/deposit_card.png" alt="deposit" className={"w-6" + opacity} />
  );
  if (name === "Withdraw") {
    icon = (
      <img
        src="/withdraw_card.png"
        alt="withdraw"
        className={"w-6" + opacity}
      />
    );
  } else if (name === "Account") {
    icon = <img src="/account.png" alt="account" className={"w-6" + opacity} />;
  }

  return (
    <button
      className={"flex flex-col text-md text-white justify-center items-center"}
      onClick={onClick}
    >
      <div className="text-2xl">{icon}</div>
      <p className={opacity}>{text}</p>
    </button>
  );
}
