import { LocaleType } from ".";

export const ruTexts: LocaleType = {
  buttonBet: "Поставить",
  buttonBetNextRound: "Поставить на следующий раунд",
  cashout: "Вывести",
  loading: "Загрузка...",
  cancel: "Отмена",
  betAmount: "Сумма ставки",
  autoCashoutAt: "Авто вывод на коэффициенте",
  targetProfit: "Максимальный выигрыш",
  chat: "Чат",
  chatShowMore: "Показать больше",
  chatShowLess: "Показать меньше",
  history: "История",
  leaderboard: "Лидеры",
  players: "Игроки",
  chatPlaceholder: "Введите ваше сообщение...",
  playersOnline: "Онлайн",
  playersPlaying: "Играет",
  playersNoPlaying: "Нет играющих пользователей",
  historyBust: "Коэффициент",
  historyProfit: "Прибыль",
  historyHash: "Хэш",
  leaderboardPlayer: "Игрок",
  leaderboardWagered: "Объем",
  leaderboardProfit: "Прибыль",
  leaderboardBets: "Ставки",
  leaderboardDay: "День",
  leaderboardWeek: "Неделя",
  leaderboardAllTime: "Все время",

  accountTotalBets: "Объем ставок",
  accountTotalWins: "Всего выигрышей",
  accountBetsCount: "Количество ставок",

  depositTitle: "Пополнить",
  depositText: "Ваш баланс Space Odyssey подключен к кошельку Punk City",
  depositButton: "Пополнить кошелек Punk City",
  depositButtonTonkeeper: "Оплатить через TonKeeper",
  depositNav: "Пополнить",

  withdrawTitle: "Вывести",
  withdrawText: "Для вывода $PUNK используйте кошелек Punk City",
  withdrawButton: "Открыть кошелек Punk City",
  withdrawNav: "Вывести",
 
  gameStarting: "Начало через",

  winChance: "Вероятность",

  manual: "обычная",
  auto: "авто",
  startAutobet: "Начать автоставку",
  stopAutobet: "Остановить автоставку",
};
