import { locale } from "../../logic/locale";
import { Button } from "../Button";
import { Input } from "../Input";
import { NewBalanceTitle, Title } from "./Deposit";
import { TokenIcon } from "./TokenIcon";

export function Withdraw() {
  return (
    <div className="flex flex-col items-center gap-2 w-full">
      <Title title={locale.texts.withdrawText} />
      {/* <div className="flex flex-row items-center mt-1">
        <SubTitle text="Available: 1000.00" />
        <TokenIcon src="punk-token.png" />
      </div>
      <div className="flex flex-row w-full h-12 gap-2 justify-center items-center mt-0">
        <Input value="10.00" iconSrc="punk-token.png" width="w-40" />
        <div className="text-xl text-white font-medium" style={{}}>
          ≈ 24.4$
        </div>
      </div>
      <SubTitle text="Your Wallet:" />
      <div className="h-12 w-full">
        <Input placeholder="0x0000000000000000000000000" />
      </div>
      <NewBalanceTitle /> */}
      <Button
        onClick={() => {}}
        color="text-grey-black"
        bgColor="bg-blue"
        link="https://t.me/PunkCity2094bot/wallet"
        text={locale.texts.withdrawButton}
        height="h-12"
      />
    </div>
  );
}

function SubTitle(props: { text: string }) {
  return (
    <div className="text-white text-lg font-medium mt-1">{props.text}</div>
  );
}
