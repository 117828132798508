export const DEFAULT_ROCKET = 99999999991;
export const DEFAULT_NICKNAME = 99999999992;

export const prizes: any = {
  2: {
    name: "Lootbox",
    image: "/lootbox.png",
  },
  1: {
    name: "Rocket",
    image: "/rocket.png",
  },
  3: {
    name: "Admin Nickname",
    image: "/colornick.png",
    color: "#9966FF"
  },
  4: {
    name: "1 Spin",
    image: "/wheel.png",
  },
  10: {
    name: "0.1 $PUNK",
    image: "/punk.png",
  },
  11: {
    name: "Blue Nickname",
    image: "/colornick.png",
    color: "#6699FF",
  },
  12: {
    name: "Aqua Nickname",
    image: "/colornick.png",
    color: "#00CC99",
  },
  13: {
    name: "Red Nickname",
    image: "/colornick.png",
    color: "#FF3333",
  },
  14: {
    name: "Pink Nickname",
    image: "/colornick.png",
    color: "#FF66CC",
  },
  15: {
    name: "Purple Nickname",
    image: "/colornick.png",
    color: "#9966FF",
  },
  99999999992: {
    name: "Default",
    image: "/colornick.png",
    color: "#68995C",
  },
  99999999991: {
    name: "Default",
    image: "/rockets/99999999991-blue.svg",
    rarity: "default",
    textColor: "#738CCD"
  },
  5: {
    name: "Iliyaeco",
    image: "/rockets/5-blue.svg",
    rarity: "blue",
    textColor: "#82C8EB",
  },
  6: {
    name: "Tonografia",
    image: "/rockets/6-blue.svg",
    rarity: "blue",
    textColor: "#8888E5"
  },
  7: {
    name: "Anddrew21",
    image: "/rockets/7-blue.svg",
    rarity: "blue",
    textColor: "#DC9999"
  },
  8: {
    name: "Inc77",
    image: "/rockets/8-purple.svg",
    rarity: "purple",
    textColor: "#DC9999",
  },
  9: {
    name: "Attwoodse",
    image: "/rockets/9-purple.svg",
    rarity: "purple",
    textColor: "#788DF8",
  },
  21: {
    name: "Walonedr",
    image: "/rockets/21-purple.svg",
    rarity: "purple",
    textColor: "#82C8EB",
  },
  16: {
    name: "6881149436",
    image: "/rockets/16-pink.svg",
    rarity: "pink",
    textColor: "#7DF17D",
  },
  17: {
    name: "Mindmesh",
    image: "/rockets/17-pink.svg",
    rarity: "pink",
    textColor: "#E383F2",
  },
  18: {
    name: "Amanitalovers",
    image: "/rockets/18-red.svg",
    rarity: "red",
    textColor: "#FFF168",
  },
  19: {
    name: "CryptoFreeBooter",
    image: "/rockets/19-red.svg",
    rarity: "red",
    textColor: "#B3A0FF"
  },
  20: {
    name: "Golden Fleece",
    image: "/rockets/20-gold.svg",
    rarity: "gold",
    textColor: "#FFCD1D"
  },
};

export var imagesToLoad: string[] = Object.values(prizes).map(
  (prize: any) => prize.image
);

export const rarityToColor: any = {
  default: {
    f: "#4F5056",
    s: "#1C1C1D",
    text: "#68995C", // default
    textRgba: hexToRgba("#68995C", 0.8),
  },
  blue: {
    f: "#3E80FF",
    s: "#00194A",
    text: "#3E80FF", // blue
    textRgba: hexToRgba("#3E80FF", 0.9),
  },
  purple: {
    f: "#702CFF",
    s: "#0A0047",
    text: "#CC33FF", // purple,
    textRgba: hexToRgba("#CC33FF", 0.7),
  },
  pink: {
    f: "#A700D1",
    s: "#292463",
    text: "#FF66CC", // pink
    textRgba: hexToRgba("#FF66CC", 0.8),
  },
  red: {
    f: "#E63A3A",
    s: "#410000",
    text: "#FFCCCC", // red
    textRgba: hexToRgba("#FFCCCC", 0.8),
  },
  gold: {
    f: "#FF8A00",
    s: "#7F1700",
    text: "#FFCC66", // gold
    textRgba: hexToRgba("#FFCC66", 0.8),
  },
};


export function hexToRgba(hex: string, alpha: number) {
  const [r, g, b] = hex
    .replace("#", "")
    .match(/.{1,2}/g)
    ?.map((x) => parseInt(x, 16)) as number[];
  return `rgba(${r},${g},${b},${alpha})`;
}