interface Props {
  iconSrc?: string;
  value?: string;
  placeholder?: string;
  width?: string;
  isReadOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export function Input(props: Props) {
  const { iconSrc, value, placeholder, width, isReadOnly } = props;
  let w = width ? width : "w-full";

  return (
    <div className={`${w} h-full relative drop-shadow-lg`}>
      {iconSrc && (
        <img className="absolute right-2 top-3 w-6 h-6" src={iconSrc} />
      )}
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        readOnly={isReadOnly}
        onChange={props.onChange}
        className=" outline-none rounded-3xl bg-grey-black text-white px-3 w-full h-12 shrink border border-grey-default font-bold shrink-0"
      ></input>
    </div>
  );
}

export function NoChangeInputDiv(props: {
  dollarsValue: number;
  punkValue: number;
}) {
  const { dollarsValue, punkValue } = props;
  return (
    <div className="w-full h-full relative drop-shadow-lg overflow-hidden whitespace-nowrap">
      <div className="rounded-md bg-grey-black text-white pl-2 w-full h-full shrink border-2 border-grey-default font-medium items-center flex">
        {dollarsValue + "$ (" + punkValue}{" "}
        <img className="w-5 h-5 mx-1" src={"/punk-token.png"} /> {")"}
      </div>
    </div>
  );
}
