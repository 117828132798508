import websocket from "../logic/websocket";
import { MAX_BET, isFloatString } from "./GameUI";

interface Props {
  onChange: (value: string) => void;
  value: string;
  error: string;
  isDollarsInput: boolean;
  isLocked?: boolean;
}
export function InputWithButtons(props: Props) {
  const { onChange, value, error, isDollarsInput, isLocked } = props;

  let additional = "";
  if (error.length) {
    additional += " border-red border-2";
  }
  return (
    <>
      {error.length ? <div className="text-red">{error}</div> : ""}
      <div className="bg-gradient-to-r from-grad-left to-grad-right p-px rounded-3xl">
        <div
          className={
            "drop-shadow-lg h-12 p-1.5 gap-0.5 flex flex-row rounded-3xl text-white items-center font-bold shrink-0 bg-grey-black gap-1" +
            additional +
            (isLocked ? " opacity-70" : "")
          }
        >
          <input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            aria-invalid="false"
            pattern="[0-9]*\.?[0-9]*"
            className="outline-none rounded-l-3xl bg-grey-black text-white pl-2 w-full h-full shrink"
            placeholder={isDollarsInput ? "0.00" : "0"}
            value={value}
            onChange={(value) => onChange(value.target.value)}
            disabled={isLocked}
            onBlur={() => {
              if (isDollarsInput) {
                if (isFloatString(value)) {
                  const parsed = parseFloat(value);
                  if (
                    websocket.user?.balance.punk &&
                    parsed > websocket.user.balance.punk / 1e9
                  ) {
                    onChange(
                      fixedNoRounding(websocket.user?.balance.punk / 1e9)
                    );
                  }
                }
              }
            }}
          ></input>
          <button
            className="h-full shrink-0 rounded-2xl bg-grey-dark text-white text-md"
            style={{ width: "50px" }}
            disabled={isLocked}
            onClick={() => {
              if (isDollarsInput) {
                if (isFloatString(value)) {
                  let parsed = parseFloat(value);
                  onChange((parsed / 2).toFixed(1) + "");
                }
              } else {
                if (isFloatString(value)) {
                  let parsed = parseFloat(value);
                  if (parsed - 1 < 1.01) {
                    parsed = 2.01;
                  }
                  onChange((parsed - 1).toFixed(2) + "");
                }
              }
            }}
          >
            {isDollarsInput ? (
              <p>1/2</p>
            ) : (
              <p className="rotate-180 flex w-full justify-center">
                <img
                  src="/arrow_drop_up.svg"
                  className="h-7 w-7"
                  style={{ filter: "invert(0.7)" }}
                />
              </p>
            )}
          </button>
          <button
            className="h-full shrink-0 rounded-2xl bg-grey-dark text-white text-md"
            style={{ width: "50px" }}
            disabled={isLocked}
            onClick={() => {
              if (isDollarsInput) {
                if (isFloatString(value)) {
                  let parsed = parseFloat(value);
                  if (parsed * 2 > 1e9) return;
                  onChange(fixedNoRounding(parsed * 2) + "");
                } else {
                }
              } else {
                if (isFloatString(value)) {
                  let parsed = parseFloat(value);
                  onChange((parsed + 1).toFixed(2) + "");
                }
              }
            }}
          >
            {isDollarsInput ? (
              <p>2x</p>
            ) : (
              <p className="rotate-0 flex w-full justify-center">
                <img
                  src="/arrow_drop_up.svg"
                  className="h-7 w-7"
                  style={{ filter: "invert(0.7)" }}
                />
              </p>
            )}
          </button>
          {/* <div className="h-1/2 w-0.5 bg-grey-black shrink-0"></div> */}
          {isDollarsInput && true && (
            <>
              <button
                className="h-full shrink-0 rounded-2xl bg-grey-dark text-white text-md"
                style={{ width: "50px" }}
                disabled={isLocked}
                onClick={() => {
                  if (!websocket.user?.balance.punk) return;
                  let newValue = websocket.user?.balance.punk / 1e9;
                  if (newValue) {
                    onChange(fixedNoRounding(Math.min(newValue, 0.1)));
                  }
                }}
              >
                <p>min</p>
              </button>
              <button
                className="h-full shrink-0 rounded-2xl bg-grey-dark text-white text-md"
                style={{ width: "50px" }}
                disabled={isLocked}
                onClick={() => {
                  if (!websocket.user?.balance.punk) return;
                  // max is 20 punk
                  let newValue = websocket.user?.balance.punk / 1e9;
                  if (newValue) {
                    onChange(fixedNoRounding(Math.min(newValue, MAX_BET)));
                  }
                }}
              >
                <p>max</p>
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export function fixedNoRounding(num: number, notAddZeroes?: boolean) {
  if (!num) return "0.00";
  let test = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)![0];

  // also add .00 if there is no decimal
  if (test.indexOf(".") == -1 && notAddZeroes) {
    test += ".00";
  }

  return test;
}
