import { rarityToColor } from "../../../components/config";
import { MainScene } from "../MainScene";
import { createFireParticles } from "./Effects";
import { getRocketData } from "./utils";
import { GameObjects } from "phaser";

const toHex = (color: string) => {
  return parseInt(color.replace("#", "0x"));
}

export function createRocketWithFire(scene: MainScene) {
  const data = getRocketData();
  
  const img = document.createElement("img");
  console.log(data.rocket)
  img.src = "assets" + data?.rocket?.image;
  img.style.height = "100px";
  img.style.width = "39px";

  scene.rocket = scene.add.dom(0, 0, img).setOrigin(0.5, 0.18);
  const color = rarityToColor[data.rocket.rarity];
  
  scene.bgRocket = scene.add.image(0, 0, 'rocket-light').setScale(0.65).setOrigin(0.51, 0.31).setTint(toHex(color.f));

  const fireEffect = createFireParticles(scene);

  scene.rocketGroup = scene.add.group();

  const htmlText = document.createElement("div");
  htmlText.style.color = "rgba(109, 141, 210, 0.4)";
  htmlText.style.fontSize = "15px";
  htmlText.style.fontFamily = "Wix Madefor Text";
  htmlText.style.fontWeight = "600";
  htmlText.style.position = "absolute";
  htmlText.style.width = "90px";
  htmlText.style.height = "20px";
  htmlText.style.textAlign = "center";

  scene.usernameText = scene.add.dom(0, 0, htmlText).setOrigin(0.8, 1.9).setAngle(-90);

  scene.rocketGroup
    .add(scene.rocket)
    .add(fireEffect)
    .add(scene.usernameText)
    .add(scene.bgRocket)
    .setXY(-2000, -2000)
}


export function updateRocketTexture(scene: MainScene, config?: { addUsername: boolean }) {
  if (!scene.rocketGroup || !scene.rocketGroup?.children?.entries) return;

  const data = getRocketData();

  scene.rocket = scene.rocketGroup.getChildren()[0] as GameObjects.DOMElement;

  if (!scene.rocket || !scene.bgRocket) return;

  (scene.rocket.node as HTMLImageElement).src = "assets" + data.rocket.image;
  scene.bgRocket.setTint(toHex(rarityToColor[data.rocket.rarity].f));
  

  if (config?.addUsername && scene.usernameText && data.username.length) {
    const span = document.createElement("span");
    span.style.color = data.nickname.color;
    span.innerHTML = data.username.length > 15 ? data.username.slice(0, 15) + "..." : data.username;
    span.style.fontWeight = "light";
    span.style.fontFamily = '"Wix Madefor Text", sans-serif'
    span.style.fontSize = "13px";

    scene.usernameText.node.innerHTML = "" + span.outerHTML;
  }
}
