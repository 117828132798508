import websocket from "../../logic/websocket";

class IntervalTimer {
  startedAt: number = 0;
  isRunning: boolean = false;
  animationFrameId: number | null = null;
  private lastUpdateTime: number = 0;

  private toUpdate?: (msTime: number) => void;
  private updateWithInterval?: (msTime: number) => void;

  canvas: HTMLCanvasElement | null = null;

  start(
    toUpdate: (ms: number) => void,
    updateWithInterval: (ms: number) => void,
  ): void {
    this.toUpdate = toUpdate;
    this.updateWithInterval = updateWithInterval;

    this.stop();

    if (!this.isRunning) {
      console.log('WEBSOCKET ELAPSED: ', websocket.currentGame?.elapsed);
      this.startedAt = Date.now() - Math.abs(websocket.currentGame?.elapsed || 0);
      this.lastUpdateTime = 0;
      this.isRunning = true;
      
      this.toUpdate(0);
      this.updateWithInterval(0);
      this.update();
    }
  }

  update(): void {
    if (this.isRunning) {
      const currentTime = Date.now();
      const elapsedTime: number = currentTime - this.startedAt;

      // Perform your interval logic here
      if (this.toUpdate) {
        this.toUpdate(elapsedTime);
        websocket.currentGame!.elapsed = elapsedTime;

        // Queue the next frame
        this.animationFrameId = requestAnimationFrame(() => this.update());
      }

      if (currentTime - this.lastUpdateTime >= 150) {
        if (this.updateWithInterval) this.updateWithInterval(elapsedTime);
        this.lastUpdateTime = currentTime;
      }
    }
  }

  stop(): void {
    this.isRunning = false;
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  private updateTarget(value: number): void {
    // Implement your logic for updating the target
  }

  getElapsed() {
    return Date.now() - this.startedAt;
  }
}

export default new IntervalTimer();
