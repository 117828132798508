import { Types } from "phaser";
import { MainScene } from "./scenes/MainScene";
import { PreloadScene } from "./scenes/PreloadScene";

const gameConfig: Types.Core.GameConfig = {
  type: Phaser.AUTO,
  dom: {
    createContainer: true,
  },
  autoFocus: true,
  physics: {
    default: "arcade",
    arcade: {
      debug: false,
    },
  },
  scale: {
    // mode: Phaser.Scale.FIT,
    // autoCenter: Phaser.Scale.CENTER_BOTH,
  },
  transparent: true,
  render: {
    powerPreference: "low-power",
    antialias: false,
    roundPixels: true,
  },

  scene: [PreloadScene, MainScene],
};

export default gameConfig;
