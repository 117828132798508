import { useEffect, useState } from "react";
import { Button } from "./Button";
import { ButtonSwitch } from "./ButtonSwitch";
import { Chat } from "./GameUI/Chat";
import { InputWithButtons } from "./InputWithButtons";
import { Players } from "./GameUI/Players";
import { History } from "./GameUI/History";
import websocket from "../logic/websocket";
import { BetStatus } from "../logic/websocket/types";
import { locale } from "../logic/locale";
import { Leaderboard } from "./GameUI/Leaderboard";

interface Props {
  size: {
    width: number;
    height: number;
  };
  chatLanguage: string;
  setIsSelectLanguageOpen: (flag: boolean) => void;
}
export const MAX_BET = 500;
export const DESKTOP_MIN_WIDTH = 1100;

export function GameUI(props: Props) {
  const { size } = props;

  let [nav, setNav] = useState<string>(
    size.width > DESKTOP_MIN_WIDTH ? "Chat" : "Chat"
  );
  let [amount, setAmount] = useState<string>("1.0");
  let [cashOut, setCashOut] = useState<string>("2");

  let [amountError, setAmountError] = useState<string>("");
  let [cashOutError, setCashOutError] = useState<string>("");

  let [isFullChat, setIsFullChat] = useState(false);

  let [betStatus, setBetStatus] = useState<BetStatus | undefined>(undefined);
  let [bankroll, setBankroll] = useState<number>(0);

  let [betMode, setBetMode] = useState<string>("manual");
  let [autoBet, setAutoBet] = useState<boolean>(false);

  useEffect(() => {
    websocket.setBetStatus = setBetStatus;
    websocket.setBankroll = setBankroll;
    websocket.setAutoBet = setAutoBet;
  }, []);

  let [button, setButton] = useState<{
    onClick: () => void;
    text: string;
    isLocked?: boolean;
    isInputsLocked?: boolean;
  }>({ onClick: () => {}, text: "Bet" });

  const handleBet = () => {
    let amountFloat = parseFloat(amount);
    let cashOutFloat: number = parseFloat(cashOut);

    if (isNaN(amountFloat) || amountFloat < 0.1 || amountFloat > MAX_BET) {
      setAmountError(`Should Be A Number (more than 0.1 and less than ${MAX_BET})`);
      return;
    }

    if (isNaN(cashOutFloat) || cashOutFloat <= 1 || cashOutFloat > 1e9) {
      setCashOutError(`Should Be A Number (more than 1 and less than 1,000,000,000)`);
      return;
    }

    // if user have more than 2 digets after dot or ,
    if (cashOutFloat.toString().split(".")[1]?.length > 2) {
      setAmountError("Should have less than 2 digits after dot in cashout");
      return;
    }

    if (
      websocket.user?.balance.punk === undefined ||
      (websocket.user?.balance.punk !== undefined &&
        amountFloat > websocket.user?.balance.punk / 1e9)
    ) {
      setAmountError("Should be less than your balance");
      return;
    }

    websocket.placeBet(amountFloat * 1e9, Math.floor(cashOutFloat * 100));
  };

  const cancelBet = () => {
    websocket.cancelBet();
  };

  const doCashOut = () => {
    websocket?.cashOut();
  };

  useEffect(() => {
    console.log(autoBet, betMode);

    if (betStatus?.isBetPlaying) {
      if (betStatus?.isBetPlayingLoad) {
        setButton({
          onClick: () => {},
          text: locale.texts.loading,
          isLocked: true,
          isInputsLocked: true,
        });
      } else if (betStatus?.state === "STARTING") {
        if (betMode === "auto") {
          if (autoBet) {
            if (!betStatus.isBetMade) {
              console.log("FROM FIRST HERE");
              handleBet();
            }

            setButton({
              onClick: () => {},
              text: locale.texts.stopAutobet,
              isLocked: true,
              isInputsLocked: true,
            });
          } else {
            setButton({
              onClick: () => {
                setAutoBet(true);
              },
              text: locale.texts.startAutobet,
              isLocked: false,
              isInputsLocked: false,
            });
          }
        } else {
          setButton({
            onClick: () => {},
            text: locale.texts.buttonBet,
            isLocked: true,
            isInputsLocked: true,
          });
        }
      } else if (betStatus?.state === "IN_PROGRESS") {
        setButton({
          onClick: () => {
            doCashOut();
          },
          text: locale.texts.cashout,
          isInputsLocked: true,
        });
      }
      // STARTING - TEXT: BET; LOCKED: TRUE
      // STARTED - TEXT: CASHOUT
    } else {
      if (betMode === "manual") {
        if (betStatus?.isBetMade) {
          setButton({
            onClick: () => {
              cancelBet();
            },
            text: locale.texts.cancel,
            isInputsLocked: true,
          });
        } else if (betStatus?.state !== "STARTING") {
          setButton({
            onClick: () => {
              handleBet();
            },
            text: locale.texts.buttonBetNextRound,
          });
        } else {
          setButton({
            onClick: () => {
              handleBet();
            },
            text: locale.texts.buttonBet,
          });
        }
      } else {
        if (autoBet) {
          if (betStatus?.state === "STARTING" && !betStatus?.isBetMade) {
            console.log("FROM HERE");
            handleBet();
          }

          setButton({
            onClick: () => {
              setAutoBet(false);
            },
            text: locale.texts.stopAutobet,
            isLocked: false,
            isInputsLocked: true,
          });
        } else {
          setButton({
            onClick: () => {
              setAutoBet(true);
            },
            text: locale.texts.startAutobet,
            isLocked: false,
            isInputsLocked: false,
          });
        }
      }
    }
  }, [betStatus, amount, cashOut, betMode, autoBet]);

  return (
    <div className="w-full bg-grey-dark flex justify-center">
      <div className="h-max flex flex-col gap-3 w-full max-w-lg mt-1">
        <Button
          color="grey-black"
          bgColor="bg-blue"
          isLocked={button.isLocked}
          text={button.text}
          onClick={button.onClick}
        />
        {!isFullChat && (
          <>
            <div className="flex flex-col p-3 bg-grey-black rounded-2xl mt-1 gap-1 pb-4">
              <p className="text-md  text-grey-title text-center">
                {locale.texts.betAmount}
              </p>

              <InputWithButtons
                isLocked={button.isInputsLocked}
                error={amountError}
                isDollarsInput={true}
                onChange={(value: string) => {
                  setAmount(value);
                  setAmountError("");
                }}
                value={amount + ""}
              />
              <p className="text-md  text-grey-title text-center mt-3">
                {locale.texts.autoCashoutAt}
              </p>
              <InputWithButtons
                isLocked={button.isInputsLocked}
                isDollarsInput={false}
                error={cashOutError}
                onChange={(value: string) => {
                  setCashOut(value);
                  setCashOutError("");
                }}
                value={cashOut + ""}
              />
              <p className="text-md  text-grey-title text-center mt-2">
                {/* Space Odyssey Pool: {bankroll ? (bankroll / 1e9).toFixed(2) : 1e9*0 / 1e9} $PUNK */}
                {locale.texts.targetProfit}:{" "}
                {isFloat(parseFloat(cashOut)) &&
                isFloat(parseFloat(amount)) &&
                parseFloat(cashOut) > 1
                  ? Math.min(
                      parseFloat(cashOut) * parseFloat(amount),
                      bankroll ? bankroll / 1e9 : 100
                    ).toFixed(2)
                  : "?"}{" "}
                $PUNK
              </p>
              {/* <p className="text-md  text-grey-title text-center">
                {locale.texts.winChance}:{" "}
                {isFloat(parseFloat(cashOut)) && isFloat(parseFloat(amount))
                  ? getWinChance(cashOut)
                  : "?"}
              </p> */}

              <div className="flex flex-row justify-center mt-2">
                {["manual", "auto"].map((mode) => (
                  <button
                    key={mode}
                    onClick={() => {
                      setBetMode(mode);
                      setAutoBet(false);
                    }}
                    className={`text-white text-md font-bold px-3 py-2 rounded-3xl ${
                      betMode === mode ? "bg-grey-dark" : "bg-grey-black"
                    }`}
                  >
                    {(locale.texts as any)[mode]}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
        <div
          style={{
            width:
              size.width > DESKTOP_MIN_WIDTH
                ? (size.width - 512) / 2 - 18
                : " ",
            height: size.width > DESKTOP_MIN_WIDTH ? size.height - 60 : " ",
          }}
          className={
            size.width > DESKTOP_MIN_WIDTH
              ? "absolute left-2 top-2 h-full max-w-lg"
              : ""
          }
        >
          {!isFullChat && (
            <div className="w-full flex justify-center">
              <ButtonSwitch
                names={
                  size.width > DESKTOP_MIN_WIDTH
                    ? ["Chat", "History", "Leaderboard"]
                    : ["Chat", "Players", "History", "Leaderboard"]
                }
                mb=" mb-2"
                mt=" mt-1"
                selected={nav}
                h={size.width > DESKTOP_MIN_WIDTH ? "h-8" : "h-12"}
                setNav={(name: string) => setNav(name)}
              />
            </div>
          )}
          {nav === "Chat" && (
            <Chat
              isDesktop={size.width > DESKTOP_MIN_WIDTH}
              isFullChat={isFullChat}
              setIsFullChat={setIsFullChat}
              size={size}
              setIsSelectLanguageOpen={props.setIsSelectLanguageOpen}
              chatLanguage={props.chatLanguage}
            />
          )}
          {nav === "Players" && (
            <Players size={size} isDesktop={size.width > DESKTOP_MIN_WIDTH} />
          )}
          {nav === "History" && <History />}
          {nav === "Leaderboard" && <Leaderboard />}
        </div>
      </div>
      {size.width > DESKTOP_MIN_WIDTH && (
        <Players size={size} isDesktop={size.width > DESKTOP_MIN_WIDTH} />
      )}
    </div>
  );
}

export const isInt = function (nVal: any) {
  return (
    typeof nVal === "number" &&
    isFinite(nVal) &&
    nVal > -9007199254740992 &&
    nVal < 9007199254740992 &&
    Math.floor(nVal) === nVal
  );
};

export const isFloatString = (str: string): boolean => {
  // Check if the string is a valid representation of a floating-point number
  return /^\d+(\.\d+)?$/.test(str);
};

export const isFloat = function (nVal: any) {
  if (isInt(nVal)) return true;
  return typeof nVal === "number" && isFinite(nVal) && nVal % 1 !== 0;
};

const getWinChance = (cashOut: string): string => {
  if (!isFloatString(cashOut)) {
    return "?";
  }

  let cashOutFloat: number = parseFloat(cashOut);

  if (cashOutFloat <= 1) {
    return "?";
  }

  // formula every 101 game

  let chance = (1 - (1 / 101 + (100 / 101) * (1 - 1 / cashOutFloat))) * 100;

  let stringChance = chance.toFixed(Math.floor(cashOutFloat).toString().length);

  // remove zeros from the end, but leave after the dot
  while (
    stringChance[stringChance.length - 1] === "0" &&
    stringChance.length > 2 &&
    stringChance[stringChance.length - 2] !== "."
  ) {
    stringChance = stringChance.slice(0, -1);
  }
  return stringChance + "%";
};
