import { DEFAULT_NICKNAME, DEFAULT_ROCKET, prizes } from "../../../components/config";
import websocket from "../../../logic/websocket";

export function getRocketData() {
    const players = websocket.players;

    let player: any = {
      username: "",
      rocket_id: null,
      nickname_id: null,
      bet: 0
    }

    // find player with max bet
    for (let id in players) {
      if (!player) {
        player = players[id];
        continue;
      }

      if (players[id].bet > player.bet ||  (players[id].bet === player.bet && players[id].username > player.username)) {
        player = players[id];
      }
    }

    let username = player.username;
    if (username.length > 15) {
      username = username.slice(0, 15) + "...";
    }

    let nicknameId = player.nickname_id;
    if (!nicknameId) nicknameId = DEFAULT_NICKNAME;

    let nickname = prizes[nicknameId];
    if (!nickname) nicknameId = DEFAULT_NICKNAME;

    let rocketId = player.rocket_id;
    if (!rocketId) rocketId = DEFAULT_ROCKET;

    let rocket = prizes[rocketId];
    if (!rocket) rocketId = DEFAULT_ROCKET;


    return {
      username,
      nickname,
      rocket,
      rocketId,
      nicknameId
    }
  }