import { fixedNoRounding } from "./InputWithButtons";
import { TokenIcon } from "./Menu/TokenIcon";

interface Props {
  onDeposit: () => void;
  user: any;
}
export function TopRightBalance(props: Props) {
  const {user, onDeposit} = props;
  // console.log('USER', user);
  return (
    <div
      className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full rounded-md text-white p-1 text-sm font-medium"
    >
      <div className="flex flex-row h-full w-full items-center">
        <TokenIcon src="/punk-token.png" mt={-2} ml={-5} height="18px" />
        <p>{user?.balance.punk ? fixedNoRounding(user.balance.punk / 1e9) : "0.00"}</p>
        <img
        src="/add_circle.svg"
          className="w-5 h-5 cursor-pointer ml-1.5"
          onClick={onDeposit}
        />
      </div>
    </div>
  );
}
