import { useEffect, useState } from "react";
import { TableHistory } from "../logic/websocket/types";
import websocket from "../logic/websocket";

export function TopLeftBusts() {
  let [gameHistory, setGameHistory] = useState<TableHistory[]>();

  useEffect(() => {
    websocket.setGameHistoryTopLeft = setGameHistory;

    if (websocket.gameHistory) {
      setGameHistory(websocket.gameHistory);
    }
  }, []);

  return (
    <div className="absolute top-2.5 left-2 flex flex-col gap-1 z-10">
      {gameHistory?.map((data, index) => {
        if (index > 5) return;
        let opacity = 1;
        let coef = Math.random() * 3 + 1;
        let bgColor = "#404FA2";
        let textColor = "#A3BCFD";
        if (data.game_crash / 100 < 2) {
          bgColor = "#20222A";
          textColor = "#8E909B";
        } 

        return (
          <div
            key={index}
            className={`w-max h-6 text-white text-sm rounded-full flex items-center font-bold justify-center px-2`}
            style={{ opacity, backgroundColor: bgColor, color: textColor }}
          >
            {data.game_crash !== 0 ? (data.game_crash / 100).toFixed(2) : "1.00"}x
          </div>
        );
      })}
    </div>
  );
}
