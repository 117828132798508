import { TEST_INIT_DATA } from "../controller";
import {
  BetStatus,
  CrashGame,
  GameData,
  GameStarted,
  GameStarting,
  Leaderboard,
  Message,
  Player,
  Players,
  Profile,
  TableHistory,
  TonPunkPrice,
} from "./types";
import server, { User } from "../server";
import { locale } from "../locale";
import { MainScene } from "../../game/scenes/MainScene";
import { DEFAULT_NICKNAME, DEFAULT_ROCKET, prizes } from "../../components/config";
import timer from "../../game/logic/timer";
import { firstTimeUpdate } from "../../components/GameUI/Chat";

class WebsocketController {
  endpointUrl = process.env.NODE_ENV === 'development'
    ? "ws://localhost:8080"
    : "wss://ton-rocket-server.pfplabs.xyz";

  ws!: WebSocket;
  currentWs: number = 0;

  user?: User;
  setUser?: (user: User) => void;

  browserInitData?: {
    token: string;
    locale: string;
  };

  isJoined = false;

  telegramInitData?: string;

  previousSentBet?: number;
  userBet?: number;

  betStatus: BetStatus = {
    state: "ENDED",
    isBetMade: false,
    isBetPlaying: false,
    isBetPlayingLoad: false,
  };

  setAutoBet?: (autoBet: boolean) => void;

  mainScene?: MainScene;

  previousPlayerInfo?: {
    bet: number;
    stopped_at?: number;
  };

  depositInput: {
    currency: string;
    amount: string;
  } = {
    currency: "TON",
    amount: "0",
  };

  tonPunkPrice?: TonPunkPrice;
  setTonPunkPrice?: (price: TonPunkPrice) => void;

  onlineUsers: number = 0;
  setOnlineUsers?: (users: number) => void;

  setBetStatus?: (status: BetStatus) => void;
  setBankroll?: (bankroll: number) => void;

  setLeaderboard?: (leaderboard: Leaderboard) => void;
  leaderboard: Leaderboard = {
    isLoading: false,
    leaderboard: [],
    sort_order: "desc",
    sort_column: "profit",
    sort_period: "all",
  };

  crashData?: CrashGame;

  setGameState?: (state: GameData) => void;

  gameHistory?: TableHistory[];
  setGameHistory?: (gameHistory: TableHistory[]) => void;
  setGameHistoryTopLeft?: (gameHistory: TableHistory[]) => void;

  chatId = 1;
  chatLocale?: string;
  setChatLocale?: (locale: string) => void;
  messages: Message[] = [];
  setMessages?: (messages: Message[]) => void;

  players: Players = {};

  setPlayers?: (data: Players) => void;

  currentGame?: GameData;

  // opened profile
  setOpenedProfile?: (profile: Profile | null) => void;
  openedProfile: Profile | null = null;

  setRouletteUrl?: (url: string) => void;
  rouletteUrl?: string;

  constructor() {
    console.log('V 2.0.1')
    this.connect();

    // console.error = (error: any) => {
    //   this.sendEvent("error", { error });
    // };
  }

  async setTelegramInitData() {
    if (typeof window !== "undefined") {
      let initData = await (window as any)?.Telegram?.WebApp?.initData;
      // console.log(JSON.stringify(initData));
      if (!initData) {
        console.log("UPDATING AGAIN");
        // get test param
        const urlParams = new URLSearchParams(window.location.search);
        const test = urlParams.get("test");
        if (test) initData = TEST_INIT_DATA;
      }

      this.telegramInitData = initData;
    }
  }

  connect() {
    console.log("CONNECTING TO WS");

    this.ws = new WebSocket(this.endpointUrl);

    setTimeout(() => {
      if (this.ws.readyState !== this.ws.OPEN) {
        console.log("Forced To Close WS Connection");
        this.ws.close();
      }
    }, 2000);

    this.ws.onopen = (event) => {
      console.log("Websocket connection opened");

      this.join();
    };

    this.ws.onerror = (error) => {
      console.error("Websocket connection error:", error);
      this.ws.close();
    };

    this.ws.onclose = (event) => {
      this.isJoined = false;
      console.log("Websocket connection closed. Reconnecting...");
      // Implement reconnecting logic here, e.g., using setTimeout
      setTimeout(() => this.connect(), 1000); // Reconnect after 1 second
    };

    this.ws.onmessage = (event) => {
      const body = JSON.parse(event.data);
      const type = body.type;

      // console.log(body, type);

      switch (type) {
        case "initial_data":
          if (!this.telegramInitData) {
            window.history.replaceState(
              {},
              document.title,
              window.location.pathname
            );
          }

          this.isJoined = true;
          const data: GameData = body.data;

          const banned = [5580229572, 304265581];
          if (banned.includes(data.user.id)) {
            alert("Your account is banned. Please contact support.");
            return;
          }

          console.log("INITIAL DATA");
          if (data.state === "ENDED" && data.crashed_at) {
            this.crashData = { game_crash: data.crashed_at };
          }

          this.currentGame = data;
          console.log(this.currentGame);
          this.onlineUsers = data.online_users;

          const players = this.currentGame.player_info;
          Object.keys(players).forEach((id) => {
            this.players[id] = { ...players[id] };

            // check if server user has bet
            if (id === data.user.id + "") {
              // if not stopped_at is undefined
              if (!this.players[id].stopped_at) {
                this.betStatus.isBetPlaying = true;
                this.betStatus.isBetMade = true;
                this.betStatus.amount = this.players[id].bet;
                this.betStatus.autoCashOut = this.previousSentBet;
              }
            }
          });

          // UPDATING GAME HISTORY
          this.gameHistory = data.table_history;

          this.user = data.user;
          this.user.auth = data.auth;
          this.user.rocketId = this.user.rocketId
            ? this.user.rocketId
            : DEFAULT_ROCKET;
          this.user.nicknameId = this.user.nicknameId
            ? this.user.nicknameId
            : DEFAULT_NICKNAME;

          if (this.user.auth.locale === "ru") {
            locale.updateLocale("ru");
          }

          this.updateRouletteUrl(data.auth.token);

          console.log("this user", this.user);

          const chatLocaleInitialData = this.user.chat_locale
            ? this.user.chat_locale
            : "english";
          // Send To Get Messages
          this.getMessages(chatLocaleInitialData);

          this.updateHistory();
          this.updateGameState();
          this.updateBetStatus();
          this.updatePlayers();
          this.updateUser();
          this.updateOnlineUsers();

          break;

        case "game_starting":
          if (!this.currentGame) return;

          const gameData: GameStarting = body.data;

          this.currentGame.game_id = gameData.game_id;
          this.currentGame.max_win = gameData.max_win;
          this.currentGame.elapsed = 0;
          this.currentGame.state = "STARTING";
          this.currentGame.isPaused = false;
          this.betStatus.cashedOut = undefined;

          setUserBetText("", { hide: true, color: "#fff" });

          if (
            this.betStatus.isBetMade &&
            this.betStatus.amount &&
            this.betStatus.autoCashOut
          ) {
            this.placeBet(this.betStatus.amount, this.betStatus.autoCashOut);
          }

          this.players = {};
          this.updatePlayers();
          this.updateGameState();
          break;
        case "game_started":
          if (!this.currentGame) return;

          timer.stop();
          this.currentGame.state = "IN_PROGRESS";
          this.currentGame.elapsed = 0;

          const gameStartedData: Players = body.data;
          this.players = gameStartedData;

          this.updatePlayers();

          if (this.user?.username) {
            const bet = gameStartedData[this.user?.id + ""];
            if (bet) {
              this.betStatus.isBetPlaying = true;
              this.betStatus.isBetMade = true;
              this.betStatus.amount = bet.bet;
              this.betStatus.autoCashOut = bet.stopped_at;
            }
          }

          this.updateGameState();
          break;
        case "game_crash":
          let playerInfo: any = {};

          if (!this.currentGame) return;

          this.currentGame.elapsed = 0;

          if (this.betStatus.isBetPlaying) {
            if (!this.previousPlayerInfo) {
              playerInfo = {
                bet: this.betStatus.amount!,
              };
            }
            this.betStatus.isBetPlaying = false;
            this.betStatus.isBetMade = false;
          }

          this.currentGame.state = "ENDED";

          let crashData: CrashGame = body.data;
          const newCashouts = crashData.cashouts;

          if (this.currentGame.bankroll) {
            this.currentGame.bankroll = crashData.bankroll!;
          }

          Object.keys(newCashouts).forEach((key) => {
            if (key !== this.user?.id + "" || this.betStatus.isBetPlaying) {
              this.mainScene?.onCashOut({
                username: this.players[key].username,
                bet: this.players[key].bet,
                stoppedAt: newCashouts[key] / 100,
                rocket_id: this.players[key].rocket_id,
                nickname_id: this.players[key].nickname_id,
              });

              if (this.user?.id + "" === key) {
                this.betStatus.cashedOut = newCashouts[key] / 100;
                this.betStatus.isBetPlaying = false;
                this.betStatus.isBetMade = false;
                this.betStatus.isBetPlayingLoad = false;

                this.previousPlayerInfo = {
                  bet: this.betStatus.amount!,
                  stopped_at: newCashouts[key],
                };

                if (this.user)
                  this.user.balance.punk +=
                    (this.betStatus.amount! * newCashouts[key]) / 100;
                this.updateUser();

                this.betStatus.amount = undefined;
                this.betStatus.autoCashOut = undefined;

                this.updateBetStatus();
              }
            }

            this.players[key].stopped_at = newCashouts[key];
          });

          this.crashData = crashData;

          this.gameHistory?.unshift({
            game_crash: crashData.game_crash,
            hash: crashData.hash!,
            game_id: this.currentGame?.game_id!,
            player_info: {
              [this.user?.id + ""]: this.previousPlayerInfo
                ? { ...this.previousPlayerInfo }
                : { ...playerInfo },
            },
            created: this.currentGame?.created!,
          });

          this.previousPlayerInfo = undefined;

          this.updateHistory();
          this.updateGameState();
          this.updatePlayers();

          break;
        case "tick":
          let serverElapsed = body.data.elapsed;
          let difference = Math.abs(serverElapsed - timer.getElapsed());
          let difference2 = Math.abs(
            serverElapsed - (this.mainScene?.elapsed || 0)
          );

          console.log("DIFFERENCE", difference);

          let cashouts = body.data.cashouts;
          // console.log("CASHOUTS", cashouts);

          Object.keys(cashouts).forEach((key) => {
            if (!this.players[key]) return;

            if (key !== this.user?.id + "" || this.betStatus.isBetPlaying) {
              this.mainScene?.onCashOut({
                username: this.players[key].username,
                bet: this.players[key].bet,
                stoppedAt: cashouts[key] / 100,
                rocket_id: this.players[key].rocket_id,
                nickname_id: this.players[key].nickname_id,
              });

              if (this.user?.id + "" === key) {
                this.betStatus.cashedOut = cashouts[key] / 100;
                this.betStatus.isBetPlaying = false;
                this.betStatus.isBetMade = false;
                this.betStatus.isBetPlayingLoad = false;

                if (!this.betStatus.amount) return;

                this.previousPlayerInfo = {
                  bet: this.betStatus.amount,
                  stopped_at: cashouts[key],
                };

                if (this.user)
                  this.user.balance.punk +=
                    (this.betStatus.amount * cashouts[key]) / 100;
                this.updateUser();

                this.betStatus.amount = undefined;
                this.betStatus.autoCashOut = undefined;

                this.updateBetStatus();
              }
            }

            this.players[key].stopped_at = cashouts[key];
          });
          if (difference > 250) {
            if (!this.currentGame) return;
            timer.stop();

            this.currentGame.elapsed = serverElapsed;

            this.updateGameState();
          }

          if (difference2 > 250) {
            if (this.mainScene) this.mainScene.elapsed = serverElapsed;
          } 

          // console.log(body.data, timer.getElapsed());
          this.updatePlayers();
          break;

        case "bets":
          const id: number = body.data[0];
          const username: string = body.data[1];
          const bet: number = body.data[2];
          const rocketId: number | null = body.data[3];
          const nicknameId: number | null = body.data[4];

          console.log("NEW BET", id, username, bet, rocketId, nicknameId);
          this.players[id] = {
            username,
            bet,
            rocket_id: rocketId,
            nickname_id: nicknameId,
          };
          this.mainScene?.onBet({
            username,
            bet,
            rocket_id: rocketId,
            nickname_id: nicknameId,
          });
          this.updatePlayers();
          break;
        case "new_message":
          if (body.data.res_locale !== this.chatLocale) return;

          const newMessage: Message = {
            id: body.data.res_id,
            chat_id: body.data.res_chat_id,
            text: body.data.res_text,
            user: {
              id: body.data.res_user_id,
              username: body.data.res_username,
              rocket_id: body.data.res_rocket_id,
              nickname_id: body.data.res_nickname_id,
            },
            created_at: body.data.res_created_at,
          };

          this.messages = [...this.messages.concat(newMessage)];
          this.updateMessages();
          break;
        case "initial_messages":
          let initialMessagesData = body.data;
          console.log("INITIAL MESSAGES", initialMessagesData);

          const chatLocale = initialMessagesData.locale;
          if (this.chatLocale !== chatLocale) {
            return;
          }

          firstTimeUpdate.isLoading = false;

          this.messages = initialMessagesData.messages;
          this.updateMessages();
          break;
        case "user_banned":
          // get user id and delete all messages from this user
          const userId = body.data.userId;
          this.messages = this.messages.filter((message) => {
            return message.user.id !== userId;
          });

          this.updateMessages();
          break;
        case "delete_message":
          this.messages = this.messages.filter((message) => {
            return message.id !== body.data.id;
          });

          this.updateMessages();
          break;
        case "bet_accepted":
          this.betStatus.isBetPlayingLoad = false;
          const amount = body.data.bet.amount;
          if (this.user) this.user.balance.punk -= amount;
          this.updateUser();
          this.updateBetStatus();
          break;
        case "bet_rejected":
          this.betStatus.isBetPlayingLoad = false;
          this.betStatus.isBetMade = false;
          this.betStatus.isBetPlaying = false;
          if (this.setAutoBet) this.setAutoBet(false);
          this.updateBetStatus();
          alert("BET REJECTED");
          break;
        case "cash_out_accepted":
          const stoppedAt = body.data.cashed_at;
          this.betStatus.isBetPlayingLoad = false;
          this.betStatus.isBetPlaying = false;
          this.betStatus.isBetMade = false;
          this.betStatus.cashedOut = stoppedAt / 100;
          this.updateBetStatus();

          if (!this.user) return;

          this.mainScene?.onCashOut?.({
            username: this.user?.username ? this.user.username : "",
            bet: this.betStatus.amount ? this.betStatus.amount : 0,
            stoppedAt: stoppedAt / 100,
            rocket_id: this.user.rocketId,
            nickname_id: this.user.nicknameId,
          });

          this.previousPlayerInfo = {
            bet: this.betStatus.amount!,
            stopped_at: stoppedAt,
          };

          if (this.user)
            this.user.balance.punk +=
              (this.betStatus.amount! * stoppedAt) / 100;
          this.updateUser();
          break;

        case "get_profile":
          console.log("GET PROFILE", body.data);
          const rockets = body.data.rockets;
          rockets.unshift({
            item_id: DEFAULT_ROCKET,
            type: "rocket",
            amount: 1,
          });
          const nicknames = body.data.nicknames;
          nicknames.unshift({
            item_id: DEFAULT_NICKNAME,
            type: "nickname",
            amount: 1,
          });

          if (!this.user) return;

          let profile: Profile = {
            user: body.data.user,
            totalBets: body.data.totalBets,
            totalProfit: body.data.totalProfit,
            betsCount: body.data.betsCount,
            isLoading: false,
            rockets: rockets,
            nicknames: nicknames,
            rocketId: this.user.rocketId,
            nicknameId: this.user.nicknameId,
          };

          profile = this.sortRockets(profile);

          if (
            this.openedProfile &&
            this.openedProfile.user.id === profile.user.id
          ) {
            this.openedProfile = {
              ...profile,
              user: { ...this.openedProfile.user },
            };

            this.updateOpenedProfile();
          }
          break;
        case "online_users":
          this.onlineUsers = body.data.liveUsers;

          this.updateOnlineUsers();
          break;
        case "get_top_earning_users":
          this.leaderboard = {
            ...this.leaderboard,
            isLoading: false,
            leaderboard: body.data,
          };

          this.updateLeaderboard();
          break;
        case "get_ton_punk_price":
          console.log("TON PUNK PRICE", body.data);
          if (body.data.ok) {
            this.tonPunkPrice = body.data.data;

            this.updateTonPunkPrice();
          }
          break;
        case "create_invoice":
          console.log("CREATE INVOICE", body.data);
          if (body.data.url) {
            if ((window as any)?.Telegram?.WebApp?.openTelegramLink) {
              (window as any)?.Telegram?.WebApp?.openTelegramLink(
                body.data.url
              );
            } else {
              window.open(body.data.url, "_blank");
            }
          }
          break;
        case "select_rocket":
          console.log("SELECT ROCKET", body.data);
          if (!body.data?.user?.id || !this.user) return;

          if (this.openedProfile && body.data.user.id === this.user?.id) {
            if (this.openedProfile.selectingRocket !== body.data.user.rocketId)
              return;

            this.user.rocketId = body.data.user.rocketId;
            this.openedProfile.selectingRocket = undefined;
            this.openedProfile.rocketId = body.data.user.rocketId;
            this.updateOpenedProfile();
            this.updateUser();
          }

          for (let i = 0; i < this.messages.length; i++) {
            if (this.messages[i].user.id === body.data.user.id) {
              this.messages[i].user.rocket_id = body.data.user.rocketId;
            }
          }

          this.updateMessages();
          break;
        case "select_nickname":
          if (!body.data?.user?.id || !this.user) return;

          if (this.openedProfile && body.data.user.id === this.user?.id) {
            if (
              this.openedProfile.selectingNickname !== body.data.user.nicknameId
            )
              return;

            this.user.nicknameId = body.data.user.nicknameId;
            this.openedProfile.selectingNickname = undefined;
            this.openedProfile.nicknameId = body.data.user.nicknameId;
            this.updateOpenedProfile();
            this.updateUser();
          }

          for (let i = 0; i < this.messages.length; i++) {
            if (this.messages[i].user.id === body.data.user.id) {
              this.messages[i].user.nickname_id = body.data.user.nicknameId;
            }
          }

          this.updateMessages();
          break;
      }
    };
  }

  updateTonPunkPrice() {
    if (this.setTonPunkPrice) this.setTonPunkPrice({ ...this.tonPunkPrice! });
  }

  updateOnlineUsers() {
    if (this.setOnlineUsers) this.setOnlineUsers(this.onlineUsers);
  }

  updateHistory() {
    this.gameHistory = this.gameHistory?.map((value) => value);

    if (this.setGameHistory) this.setGameHistory(this.gameHistory!);
    if (this.setGameHistoryTopLeft)
      this.setGameHistoryTopLeft(this.gameHistory!);
  }

  updateGameState() {
    if (!this.currentGame) return;

    this.currentGame = { ...this.currentGame };
    if (this.setGameState && this.currentGame)
      this.setGameState(this.currentGame);

    if (this.setBankroll && this.currentGame)
      this.setBankroll(this.currentGame.bankroll);

    this.updateBetStatus();
  }

  updateBetStatus() {
    if (!this.setBetStatus || !this.currentGame) return;

    this.betStatus.state = this.currentGame.state;

    this.betStatus = { ...this.betStatus };
    this.setBetStatus(this.betStatus);
  }

  updateMessages() {
    if (this.setMessages) {
      this.setMessages([...this.messages]);
    }
  }

  updatePlayers() {
    if (!this.currentGame) return;

    this.players = { ...this.players };
    if (this.setPlayers) this.setPlayers(this.players);

    if (this.mainScene) {
      this.mainScene.updateCaptain();
    }
  }

  sortRockets(profile: Profile) {
    if (!profile.rockets) return profile;

    // sort 1 gold 2 red 3 pink 4 purple 5 blue and others
    const newRockets = profile.rockets.sort((a, b) => {
      const prize1 = prizes[a.item_id];
      const prize2 = prizes[b.item_id];

      let rarity1 = prize1.rarity;
      let rarity2 = prize2.rarity;

      let rarityToNumber: any = {
        gold: 1,
        red: 2,
        pink: 3,
        purple: 4,
        blue: 5,
        default: 6,
      };

      if (!rarityToNumber[rarity1]) rarity1 = "default";
      if (!rarityToNumber[rarity2]) rarity2 = "default";

      if (rarityToNumber[rarity1] <= rarityToNumber[rarity2]) return -1;
      else return 1;
    });

    profile.rockets = newRockets;

    return profile;
  }

  updateOpenedProfile() {
    if (this.openedProfile) this.openedProfile = { ...this.openedProfile };

    if (this.setOpenedProfile) this.setOpenedProfile(this.openedProfile);
  }

  updateUser() {
    if (!this.user || !this.setUser) return;

    this.user = { ...this.user };
    this.setUser(this.user);
  }

  sendEvent(type: string, data: any) {
    try {
      console.log("trying to send event", type, data);
      if (this.ws.OPEN === this.ws.readyState) {
        this.ws.send(JSON.stringify({ type, data }));
      }
    } catch (e) {
      console.log(e);
    }
  }

  async join() {
    console.log("TRYING TO JOIN");
    await this.setTelegramInitData();
    this.setBrowserData();

    this.sendEvent("join", {
      initData: this.telegramInitData,
      browserInitData: this.telegramInitData ? undefined : this.browserInitData,
    });

    if (!this.telegramInitData?.length && !this.browserInitData) {
      console.log(this.browserInitData);
      console.log("Should Open In Telegram");

      setTimeout(() => {
        setText("Please Login with TG Bot", { fontSize: 20, color: "#fff" });
        setUserBetText("@SpaceOdysseyPlay_bot", { hide: false, color: "#fff" });
      }, 2000);

      return;
    }

    // setTimeout(() => {
    //   if (!this.isJoined) {
    //     this.join();
    //   }
    // }, 2000);
  }

  setBrowserData() {
    if (!window) return;
    if (typeof window === 'undefined') return

    // get token & locale from params
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const locale = urlParams.get("locale");

    if (token && locale) {
      this.browserInitData = { token, locale };

      localStorage.setItem("token", token);
      localStorage.setItem("locale", locale);

      window.history.replaceState({}, document.title, window.location.pathname);
    } else {
      // try to get from local storage
      const token = localStorage.getItem("token");
      const locale = localStorage.getItem("locale");
      if (token && locale) {
        this.browserInitData = { token, locale };
      }

      console.log(this.browserInitData);
    }
  }

  placeBet(amount: number, autoCashOut: number) {
    this.betStatus.isBetMade = true;
    this.betStatus.amount = amount;
    this.betStatus.autoCashOut = autoCashOut;
    this.updateBetStatus();

    if (this.currentGame?.state !== "STARTING") return;

    this.previousSentBet = autoCashOut;

    this.betStatus.isBetPlaying = true;
    this.betStatus.isBetPlayingLoad = true;
    this.updateBetStatus();

    this.sendEvent("place_bet", { amount, autoCashOut });
  }

  cashOut() {
    this.betStatus.isBetPlayingLoad = true;
    this.updateBetStatus();

    this.sendEvent("cash_out", {});
  }

  selectRocket(rocketId: any) {
    if (!this.openedProfile) return;

    this.openedProfile.selectingRocket = rocketId;

    this.sendEvent("select_rocket", { rocketId });

    this.updateOpenedProfile();
  }

  selectNickname(nicknameId: number) {
    if (!this.openedProfile) return;

    this.openedProfile.selectingNickname = nicknameId;

    this.sendEvent("select_nickname", { nicknameId });

    this.updateOpenedProfile();
  }

  cancelBet() {
    this.betStatus.isBetMade = false;

    this.updateBetStatus();
  }

  createInvoice(amount: string, provider: "cryptobot") {
    if (!this.user?.id) return;
    if (parseFloat(amount) < 0.01 || parseFloat(amount) > 1000000) return;

    let amountNumber = parseFloat(amount);

    this.sendEvent("create_invoice", {
      amount: amountNumber,
      user_id: this.user.id,
      provider,
    });
  }

  getTonPunkPrice(amount: string, currency: "TON" | "PUNK") {
    this.sendEvent("get_ton_punk_price", { amount, currency });
  }

  sendMessage(text: string) {
    this.sendEvent("send_message", { locale: this.chatLocale, text });
  }

  updateRouletteUrl(token: string) {
    let endpointUrl = process.env.NEXT_PUBLIC_IS_DEV
      ? "http://localhost:3001"
      : "https://roulette.pfplabs.xyz";

    const redirectUrl = window.origin + `/?token=${token}&locale=en`;
    const url = `${endpointUrl}/?token=${token}&locale=en&redirect=${encodeURIComponent(
      redirectUrl
    )}&n=${new Date().getTime()}`;

    this.rouletteUrl = url;
    this.setRouletteUrl?.(url);
  }

  getMessages(locale: string) {
    if (this.chatLocale === locale) return;

    this.chatLocale = locale;

    this.sendEvent("get_messages", { locale });

    firstTimeUpdate.flag = true;
    firstTimeUpdate.isLoading = true;
    this.messages = [];
    this.updateMessages();
    this.setChatLocale?.(locale);
  }

  getProfile(id: number, username: string) {
    this.openedProfile = {
      user: {
        id,
        username,
      },
      rocketId: null,
      nicknameId: null,
      isLoading: true,
    };

    this.sendEvent("get_profile", { user: { id } });

    this.updateOpenedProfile();
  }

  getTopEarningUsers(
    sort_column: string,
    sort_order: string,
    sort_period: string
  ) {
    this.leaderboard.isLoading = true;
    this.leaderboard.sort_column = sort_column;
    this.leaderboard.sort_order = sort_order;
    this.leaderboard.sort_period = sort_period;

    this.sendEvent("get_top_earning_users", {
      sort_column,
      sort_order,
      sort_period,
    });
    this.updateLeaderboard();
  }

  updateLeaderboard() {
    this.leaderboard = { ...this.leaderboard };

    if (this.setLeaderboard) {
      this.setLeaderboard(this.leaderboard);
    }
  }
}

export default new WebsocketController();

export function setText(
  text: string,
  config?: { fontSize?: number; color?: string; marginTop?: number }
) {
  const div = document.getElementById("multiplyer");
  if (!div) return;

  let fontSize = config?.fontSize ? config.fontSize + "px" : "2.75rem";
  let color = config?.color ? config?.color : "#6D8DD2";
  div.style.fontSize = fontSize;
  div.style.color = color;
  div.style.marginTop = config?.marginTop ? config.marginTop + "px" : "0px";

  if (div) {
    div.innerHTML = text;
  }
}

export function setUserBetText(
  text: string,
  config?: {
    hide?: boolean;
    fontSize?: number;
    color?: string;
    marginTop?: number;
    weight?: string;
    spanText?: {
      text: string;
      color: string;
    };
  }
) {
  const div = document?.getElementById("user-bet");
  if (!div) return;

  div.style.display = config?.hide ? "none" : "block";
  div.style.color = config?.color ? config.color : "#6D8DD2";
  div.style.fontSize = config?.fontSize ? config.fontSize + "px" : "1.2rem";
  div.style.marginTop = config?.marginTop ? config.marginTop + "px" : "0px";
  div.style.fontWeight = config?.weight ? config.weight : "bold";

  // create span
  let span;
  if (config?.spanText) {
    span = document.createElement("span");
    span.innerHTML = config.spanText.text;
    span.style.color = config.spanText.color;
    span.style.marginLeft = "5px";
    span.style.fontSize = config?.fontSize ? config.fontSize + "px" : "1.2rem";
    span.style.fontWeight = config?.weight ? config.weight : "bold";
  }
  div.innerHTML = text + (span ? span.outerHTML : "");
}
