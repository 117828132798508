import { useEffect, useState } from "react";
import { ButtonSwitch } from "../ButtonSwitch";
import { Input, NoChangeInputDiv } from "../Input";
import { TokenIcon } from "./TokenIcon";
import { Button } from "../Button";
import { isFloat } from "../GameUI";
import { DNA, Oval } from "react-loader-spinner";
import { fixedNoRounding } from "../InputWithButtons";
import { TonPunkPrice } from "../../logic/websocket/types";
import websocket from "../../logic/websocket";
import { locale } from "../../logic/locale";

let timer: any = undefined;

let previousTonAmount = "0";
let previousPunkAmount = "0";
export function Deposit() {
  let [nav, setNav] = useState("Wallets");

  let [tonPunkPrice, setTonPunkPrice] = useState<TonPunkPrice>();
  let [punkAmount, setPunkAmount] = useState("");
  let [tonAmount, setTonAmount] = useState("100");

  let [isLoading, setIsLoading] = useState(false);

  let [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    websocket.setTonPunkPrice = (data) => {
      if (data) {
        console.log(data.tonAmount, previousTonAmount);
        // check if already change ton amount or punk amount
        if (data.tonAmount.toString() !== previousTonAmount) {
          return;
        }

        setIsLoading(false);
        setTonPunkPrice(data);
        setPunkAmount(fixedNoRounding(parseFloat(data.punkAmount)));
        setTonAmount(fixedNoRounding(parseFloat(data.tonAmount)));
      }
    };

    sendAmount(tonAmount, "TON", setIsLoading);
    setIsLoading(true);
  }, []);

  return (
    <div className="flex flex-col items-center gap-2 w-full pb-20 overflow-y-scroll removeScrollbarClass">
      <ButtonSwitch
        names={["Wallets", "PunkCity"]}
        selected={nav}
        setNav={(name) => setNav(name)}
        px=" px-5"
        mt=" mt-2"
      />
      {nav === "PunkCity" ? (
        <>
          <Title title={locale.texts.depositText} />
          <TopUpButtons />
        </>
      ) : (
        <>
          <div className="mt-0" />
          <div className="flex flex-row w-full h-12 gap-2 justify-center items-center mt-1">
            <Input
              width="w-40"
              iconSrc="/ton-token.png"
              value={tonAmount.toString()}
              placeholder="0.00"
              onChange={(e) => {
                setTonPunkPrice(undefined);
                setTonAmount(e.target.value);
                sendAmount(
                  fixedNoRounding(parseFloat(e.target.value)),
                  "TON",
                  setIsLoading
                );
                setIsPressed(false);
              }}
            />
          </div>
          {isLoading ? (
            <>
              <DNA
                visible={true}
                height="50"
                width="50"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </>
          ) : (
            tonPunkPrice && (
              <>
                <div className="text-white text-sm flex flex-row items-center font-medium mt-2">
                  You Will Receive:
                  <span className="ml-1">
                    {fixedNoRounding(parseFloat(tonPunkPrice.punkAmount))}
                  </span>
                  <TokenIcon src="punk-token.png" height="18px" ml={3} />
                  <span className="ml-1">
                    {"≈ " +
                      fixedNoRounding(
                        parseFloat(tonPunkPrice.punkUsdPrice),
                        true
                      ) +
                      "$"}
                  </span>
                </div>
                <div className="text-white text-sm flex flex-row items-center font-medium">
                  Conversion Fee:{" "}
                  {(
                    (parseFloat(tonPunkPrice.protocolFeePaid) /
                      parseFloat(tonPunkPrice.tonAmount)) *
                    100
                  ).toFixed(2)}
                  %
                </div>
                <div className="text-white text-md flex flex-row items-center font-medium mt-1">
                  Select Payment Method:
                </div>
                {/* <NewBalanceTitle tonPunkPrice={tonPunkPrice} /> */}
                {isPressed && (
                  <div
                    className="flex flex-row h-max w-full gap-2 justify-center items-center mb-1 "
                    style={{ marginTop: -5 }}
                  >
                    <Oval
                      visible={true}
                      height="20"
                      width="20"
                      color="#4fa94d"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                    <p className="text-white text-sm flex flex-row items-center font-medium mt-2">
                      If you sent the transaction, please wait for the
                      confirmation up to 5 minutes.
                    </p>
                  </div>
                )}
                <div className="grid grid-cols-2 gap-2 w-full justify-center items-center">
                  <Button
                    onClick={() => {
                      setIsPressed(true);
                    }}
                    icon="/tonkeeper.png"
                    link={getTonkeeperLink(tonPunkPrice)}
                    color="text-grey-black"
                    bgColor="bg-blue"
                    text={"Tonkeeper"}
                    height="h-10"
                    maxW="max-w-48"
                  />

                  <Button
                    onClick={() => {
                      setIsPressed(true);
                    }}
                    icon="/tonhub.png"
                    link={getTonhubLink(tonPunkPrice)}
                    color="text-grey-black"
                    bgColor="bg-blue"
                    text={"Tonhub"}
                    height="h-10"
                    maxW="max-w-48"
                  />
                  <Button
                    onClick={() => {
                      if (!tonPunkPrice?.tonAmount) return;

                      websocket.createInvoice(
                        tonPunkPrice.tonAmount,
                        "cryptobot"
                      );
                    }}
                    icon="/cryptobot.png"
                    color="text-grey-black"
                    bgColor="bg-blue"
                    text={"CryptoBot"}
                    height="h-10"
                    maxW="max-w-48"
                  />
                </div>
              </>
            )
          )}
        </>
      )}
    </div>
  );
}

export function Title(props: { title: string }) {
  const { title } = props;

  return (
    <h1 className="text-lg font-medium text-grey-title text-center mt-2">
      {title}
    </h1>
  );
}

export function NewBalanceTitle(props: {
  tonPunkPrice: TonPunkPrice | undefined;
}) {
  if (!props.tonPunkPrice) return null;

  return (
    <div className="text-white text-md flex flex-row items-center font-medium mt-2 mb-1">
      You Will Receive:
      <span className="ml-1">
        {fixedNoRounding(parseFloat(props.tonPunkPrice.punkAmount))}
      </span>
      <TokenIcon src="punk-token.png" height="18px" ml={3} />
      <span className="ml-1">
        {"≈ " +
          fixedNoRounding(parseFloat(props.tonPunkPrice.punkUsdPrice), true) +
          "$"}
      </span>
    </div>
  );
}

function TopUpButtons() {
  return (
    <>
      <Button
        onClick={() => {}}
        link="https://t.me/PunkCity2094bot/wallet"
        color="text-grey-black"
        bgColor="bg-blue"
        text={locale.texts.depositButton}
        height="h-12"
      />
      {/* <Button
        onClick={() => {}}
        color="text-white"
        bgColor="bg-blue"
        text="Deposit With Tonhub"
        height="h-12"
      /> */}
    </>
  );
}

function sendAmount(
  amount: string,
  currency: "TON" | "PUNK",
  setIsLoading: any
) {
  let parsed = parseFloat(amount);
  if (isNaN(parsed)) {
    return;
  }

  amount = parsed.toString();

  if (parsed < 0.01 || parsed > 1000000) return;

  console.log(parsed);
  if (timer) {
    clearTimeout(timer);
  }

  websocket.depositInput = {
    amount: amount,
    currency: currency,
  };

  if (currency === "TON") {
    previousTonAmount = amount;
  } else {
    previousPunkAmount = amount;
  }

  timer = setTimeout(() => {
    setIsLoading(true);
    websocket.getTonPunkPrice(amount, currency);
  }, 500);
}

function getTonkeeperLink(tonPunkPrice: TonPunkPrice) {
  console.log("BUTTON", tonPunkPrice.tonAmount);
  console.log("user id", websocket.user?.id);
  let url =
    "https://app.tonkeeper.com/transfer/" +
    tonPunkPrice.walletAddress +
    "?amount=" +
    Math.floor(parseFloat(tonPunkPrice.tonAmount) * 1e9) +
    "&text=" +
    websocket.user?.id;
  return url;
}

// https://tonhub.com/transfer/UQBymNAgmCuKJXZwmvOgbRsge2nN8K2PEssPr7vALjMGhTPd?amount=5000000000&text=132
function getTonhubLink(tonPunkPrice: TonPunkPrice) {
  let url =
    "https://tonhub.com/transfer/" +
    tonPunkPrice.walletAddress +
    "?amount=" +
    Math.floor(parseFloat(tonPunkPrice.tonAmount) * 1e9) +
    "&text=" +
    websocket.user?.id;
  return url;
}
