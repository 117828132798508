import { Nickname } from "./Nickname";

interface Props {
  onClick: () => void;
  user: any;
}
export function TopLeftProfile(props: Props) {
  const { user, onClick } = props;

  return (
    <div className="absolute left-0 top-1/2 transform -translate-y-1/2  flex flex-row w-max h-full">
      <button className="w-max flex items-center" onClick={onClick}>
        {user && (
          <Nickname
            className="max-w-24"
            user={{
              username: user.username,
              rocket_id: user.rocketId,
              nickname_id: user.nicknameId,
            }}
            onClick={() => {}}
          />
        )}
      </button>
    </div>
  );
}
