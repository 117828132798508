import { useEffect, useState } from "react";
import { User } from "./logic/server";
import { prizes } from "./components/config";
import { SelectLanguage } from "./components/SelectLanguage";
import { Profile } from "./components/Profile";
import { GameUI } from "./components/GameUI";
import { TopRightBrowser } from "./components/TopRightBrowser";
import { TopRightBalance } from "./components/TopRightBalance";
import { TopLeftProfile } from "./components/TopLeftProfile";
import websocket from "./logic/websocket";
import { RouletteIframe } from "./components/RouletteIframe";
import { Menu } from "./components/Menu";
import useWindowSize from "./hooks/useWindowSize";
import { Profile as ProfileType } from "./logic/websocket/types";
import Game from "./components/Game";
const isDevelopingUI = false;

function App() {
  let [menu, setMenu] = useState<string | null>(null);

  let [userData, setUserData] = useState<User>();

  let [openedProfile, setOpenedProfile] = useState<ProfileType | null>(null);

  const size = useWindowSize();

  let [chatLocale, setChatLocale] = useState("english");
  let [isSelectChatLocale, setIsSelectChatLocale] = useState(false);
  let [iframe, setIframe] = useState(false);

  useEffect(() => {
    (window as any).telegramAnalytics?.init({
      token:
        "eyJhcHBfbmFtZSI6InNwYWNlX29keXNzZXlfbmV3IiwiYXBwX3VybCI6Imh0dHBzOi8vdC5tZS9TcGFjZU9keXNzZXlQbGF5X2JvdCIsImFwcF9kb21haW4iOiJodHRwczovL29keXNzZXkucGZwbGFicy54eXovIn0=!RIwQp2rNSemtEdoLMfZxP6tk1+Jw2XYRFYCp21HdKG0=",
      appName: "space_odyssey_new",
    });

    websocket.setUser = setUserData;
    websocket.setTelegramInitData();
    websocket.setOpenedProfile = setOpenedProfile;
    websocket.setChatLocale = setChatLocale;

    preloadImages();
  }, []);

  useEffect(() => {}, [size]);

  return (
    <main>
      {menu && <Menu startNav={menu} onClose={() => setMenu(null)} />}
      {iframe && <RouletteIframe setIframe={setIframe}/>}
      <div className="h-max w-full min-h-screen min-w-screen bg-grey-dark">
        <div className="h-full w-full flex flex-col m-0 p-3 gap-3 items-center">
          <div className="w-full max-w-lg relative h-8">
            <TopLeftProfile
              user={userData}
              onClick={() => {
                if (!userData) return;
                websocket.getProfile(userData.id, userData.username);
              }}
            />
            <TopRightBalance
              user={userData}
              onDeposit={() => setMenu("Deposit")}
            />
            <TopRightBrowser user={userData} />
          </div>
          {isDevelopingUI ? (
            <div className="gameContainer w-full flex-grow h-60 block m-0 p-0 bg-black shrink-0"></div>
          ) : (
            <>
              <Game setIframe={setIframe} />
            </>
          )}
          <GameUI
            size={size}
            setIsSelectLanguageOpen={setIsSelectChatLocale}
            chatLanguage={chatLocale}
          />
          {openedProfile && <Profile profile={openedProfile} />}
          {isSelectChatLocale && (
            <SelectLanguage
              isSelectLanguageOpen={isSelectChatLocale}
              setIsSelectLanguageOpen={setIsSelectChatLocale}
              chatLanguage={chatLocale}
              setChatLanguage={setChatLocale}
            />
          )}
        </div>
      </div>
    </main>
  );
}

function preloadImages() {
  let rockets = Object.values(prizes).map((prize: any) => {
    if (prize.rarity) {
      return "/assets" + prize.image;
    }
  });

  const images = ["/account.png", "/deposit_card.png", "/withdraw_card.png", ...rockets];

  images.forEach((image) => {
    if (!image) return;
    new Image().src = image;
  });

  console.log("Preloaded images " + images.length);
}


export default App;

