import { useEffect } from "react";
import { BlackoutBackdrop } from "./BlackoutBackdrop";
import { IoIosClose } from "react-icons/io";

interface Props {
  onClose: () => void;
  children?: any;
}

const previousPosition = {
  bodyScrollTop: 0,
  documentElementScrollTop: 0,
};

export function Panel(props: Props) {
  const { onClose, children } = props;

  useEffect(() => {
    previousPosition.bodyScrollTop = document.body.scrollTop;
    previousPosition.documentElementScrollTop = document.documentElement.scrollTop;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    document.body.style.overflow = "hidden";

    return () => {
      document.body.scrollTop = previousPosition.bodyScrollTop;
      document.documentElement.scrollTop = previousPosition.documentElementScrollTop;
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <BlackoutBackdrop
        onClick={() => {
          onClose();
        }}
      />
      <div
        className="absolute top-1/2 left-1/2 bg-grey-dark p-6 -translate-x-1/2 -translate-y-1/2 rounded-3xl max-w-sm w-full z-50"
        style={{ height: "31rem" }}
      >
        <button
          className="text-4xl absolute right-4 top-4 text-grey-title"
          onClick={() => {
            onClose();
          }}
        >
          <IoIosClose />
        </button>
        <div className="flex flex-col h-full w-full items-center gap-1 overflow-y-scroll removeScrollbarClass">
          {children}
        </div>
      </div>
    </>
  );
}
