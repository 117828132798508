import { locale } from "../logic/locale";

interface Props {
  names: string[];
  selected: string;
  mt?: string; // add only with space
  mb?: string; // add only with space
  px?: string; // add only with space
  h?: string
  setNav?: (name: string) => void;
}
export function ButtonSwitch(props: Props) {
  let { names, selected, mt, px, setNav, mb, h } = props;
  if (!mt) mt = " ";
  if (!px) px = " ";
  if (!mb) mb = " ";
  if (!h) h = "h-12"

  return (
    <div className={"w-max bg-gradient-to-r from-grad-left to-grad-right p-px rounded-3xl " + mt + mb}>
      <div
        className={
          `shrink-0 w-min rounded-full flex flex-row ${h} bg-grey-black text-white drop-shadow-lg font-medium p-1`
        }
      >
        {names.map((name, index) => {
          const bgColor = selected === name ? " bg-white" : " none";
          const textColor = selected === name ? " text-grey-black" : " text-white";
          let text = locale.texts.chat;
          switch (name) {
            case "Chat":
              text = locale.texts.chat;
              break;
            case "Players":
              text = locale.texts.players;
              break;
            case "History":
              text = locale.texts.history;
              break;
            case "Leaderboard":
              text = locale.texts.leaderboard;
              break;
            default:
              text = name;
          }

          return (
            <button
              key={index}
              className={"rounded-full text-sm px-3 h-full" + bgColor + px + textColor}
              onClick={() => {
                if (setNav) setNav(name);
              }}
            >
              {text}
            </button>
          );
        })}
      </div>
    </div>
  );
}
